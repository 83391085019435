<template>
    <div>
        <BHLoading :show="loading" />
        <a-modal :visible="visible" :centered="true" :footer="null" @cancel="closeModal" :width="650">
            <div class="dF aC">
                <h5 style="margin:0" class="mr-3">Form Submission</h5>
            </div>
            <div v-if="Object.keys(previewForm).length != 0 && previewForm.data && previewForm.data.fields && previewForm.data.fields.length">
                <div v-if="previewForm.data.fields[0] && previewForm.data.fields[0].key == 'url'" class="dF aC"><div>{{`Form Url: `}}</div>&nbsp;<div style="color:var(--orange)"><a :href="previewForm.data.fields[0].value" target="_blank" v-html="previewForm.data.fields[0].value"></a></div></div>
                <a-row class="mt-5" :gutter="[16, 16]" v-if="previewForm.form && previewForm.form.fields">
                    <div v-for="(field) in reformat(previewForm.form.fields)" :key="field.id" :value="field.id">
                        <template v-if="field.type != 'header'">
                            <a-col :span="12">
                                <div style="color:#000">{{field.label}}</div>
                                <a :href="field.value" target="_blank" v-if="field.type == 'file'" v-html="field.fileName"></a>
                                <div v-else v-html="field.value"></div>
                            </a-col>
                        </template>
                        <template v-else>
                            <a-col :span="24">
                                <a-collapse>
                                    <a-collapse-panel :key="field.id" :header="field.label">
                                        <a-col :span="12" v-for="child in field.children" :key="child.id" :value="child.id">
                                            <div style="color:#000" v-html="child.label"></div>
                                            <a :href="child.value" target="_blank" v-if="child.type == 'file'" v-html="child.fileName"></a>
                                            <div v-else-if="child.type == 'checkbox' && typeof child.value == 'object' && child.value.length">
                                                <div v-for="(ans,ansI) in child.value" :value="ans+ansI" :key="ans+ansI" v-html="ans"></div>
                                            </div>
                                            <div v-else v-html="child.value"></div>
                                        </a-col>
                                    </a-collapse-panel>
                                </a-collapse>
                            </a-col>
                        </template>
                    </div>
                </a-row>
            </div>
        </a-modal>
        <a-card>
            <template v-if="forms.length">
                <div :class="formI == 0 ? '' : 'mt-4'" class="dF jSB" v-for="(form, formI) in forms" :key="form.id" :value="form.id">
                    <div class="dF">
                        <div class="smallIcon mr-3" style="background-color: #FFDEAD;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="#F79425" stroke="#F79425" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-align-justify"><line x1="21" y1="10" x2="3" y2="10"></line><line x1="21" y1="6" x2="3" y2="6"></line><line x1="21" y1="14" x2="3" y2="14"></line><line x1="21" y1="18" x2="3" y2="18"></line></svg>
                        </div>
                        <div>
                            <div style="color:black; font-size:15px">{{form.form && form.form.name ? form.form.name : 'N/A'}}</div>
                            <div style="color:#9EA0A5">{{getDateString(form.createdAt)}}</div>
                            <div style="color:var(--orange)" v-if="form.contact">{{form.contact.firstName && form.contact.lastName && form.contact.firstName != '' && form.contact.lastName != '' ? `Submitted by ${form.contact.firstName} ${form.contact.lastName}` :''}}</div>
                        </div>
                    </div>
                    <div>
                        <a-tooltip slot="extra" overlayClassName="change-tooltip-color" class="mr-2">
                            <template slot="title">View Form</template>
                            <a-icon @click="openForm(form)" style="font-size:16px; cursor:pointer" type="eye" class="action-icons" />
                        </a-tooltip>
                    </div>
                </div>
            </template>
            <template v-else>This lead doesn't have any form...</template>
        </a-card>
    </div>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'
export default {
    components:{BHLoading},
    props:{
        id:{
            type:String,
            default:''
        },
        contact:{
            type:Object,
            default: () => {}
        }
    },
    data() {
        return{
            forms:[],
            previewForm:{},
            attachments:[],
            loading:false,
            visible:false,
        }
    },
	computed:{
		dateFormat(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
	},
    methods:{
        reformat(array) {
            let data = this.previewForm.data && this.previewForm.data.fields || []
            let fields = []
            let isHeader = false
            let headerObj = {
                id:'',
                value:'',
                label:'',
                type:'',
                children:[]
            }
            array.forEach(field => {
                let obj = {
                    id:'',
                    value:'',
                    label:'',
                    type:'',
                }
                if (field.type != 'header') {
                    if (!field.hide) {
                        if (field.type == 'name') {
                            let first = {}
                            first.id = 'firstName'
                            first.type = 'name'
                            let firstName = data.find (x => x.key && x.key == 'firstName')
                            if (firstName) {
                                first.label = 'First Name'
                                first.value = firstName.value
                            }
                            let last = {}
                            last.id = 'lastName'
                            last.type = 'name'
                            let lastName = data.find (x => x.key && x.key == 'lastName')
                            if (lastName) {
                                last.label = 'Last Name'
                                last.value = lastName.value
                            }
                            if (!isHeader){
                                fields.push(first)
                                fields.push(last)
                            } else {
                                headerObj.children.push(first)
                                headerObj.children.push(last)
                            }

                            let properties = ['middleInitial','prefix','suffix']
                            properties.forEach(key => {
                                if (field[key]) {
                                    let object = {}
                                    object.id = key
                                    object.type = 'name'
                                    let find = data.find(x => x.key && x.key == key)
                                    if (find) {
                                        object.label = find.label
                                        object.value = find.value
                                    }
                                    if (!isHeader){
                                        fields.push(object)
                                    } else {
                                        headerObj.children.push(object)
                                    }
                                }
                            })
                        } else if (field.type == 'phone') {
                            obj.id = 'phone'
                            obj.type = 'phone'
                            let find = data.find(x => x.key && x.key == 'phone')
                            if (find) {
                                obj.label = 'Phone'
                                obj.value = find.value
                            }
                            if (!isHeader){
                                fields.push(obj)
                            } else {
                                headerObj.children.push(obj)
                            }
                        } else if (field.type == 'email') {
                            obj.id = 'email'
                            obj.type = 'email'
                            let find = data.find(x => x.key && x.key == 'email')
                            if (find) {
                                obj.label = 'Email'
                                obj.value = find.value
                            }
                            if (!isHeader){
                                fields.push(obj)
                            } else {
                                headerObj.children.push(obj)
                            }
                        } else if (field.type == 'address') {
                            if (field.options.addressOne) {
                                let add1 = {}
                                add1.id = 'address'
                                add1.type = 'address'
                                let find = data.find(x => x.key && x.key == 'address')
                                if (find) {
                                    add1.value = find.value
                                    add1.label = find.label
                                }
                                if (!isHeader){
                                    fields.push(add1)
                                } else {
                                    headerObj.children.push(add1)
                                }
                            }
                            if (field.options.addressTwo) {
                                let add2 = {}
                                add2.id = 'address2'
                                add2.type = 'address'
                                let find = data.find(x => x.key && x.key == 'address2')
                                if (find) {
                                    add2.value = find.value
                                    add2.label = find.label
                                }
                                if (!isHeader){
                                    fields.push(add2)
                                } else {
                                    headerObj.children.push(add2)
                                }
                            }
                            let properties = ['city', 'country', 'postal', 'province']
                            properties.forEach(key => {
                                if (field.options[key]) {
                                    let object = {}
                                    object.id = key
                                    object.type = 'address'
                                    if (key != 'province') {
                                        let find = data.find(x => x.key && x.key == key)
                                        console.log('finddddd',find)
                                        if (find) {
                                            object.label = find.label
                                            object.value = find.value
                                        }
                                    } else {
                                        let find = data.find(x => x.key && x.key == 'region')
                                        if (find) {
                                            object.label = find.label
                                            object.value = find.value
                                        }
                                    }
                                    if (!isHeader){
                                        fields.push(object)
                                    } else {
                                        headerObj.children.push(object)
                                    }
                                }
                            })
                        } else if (field.type == 'isAgent') {
                            obj.id = 'isAgent'
                            obj.type = 'isAgent'
                            obj.label = 'Is Agent?'
							let agentValue = data.find(x => x.key && x.key == 'isAgent')
							console.log('IS AGENT FIELD VALUE', agentValue)
                            if (agentValue && (agentValue.value == '1' || agentValue.value == 'true' || agentValue.value == true)){
                                obj.value = 'Yes'
                            } else {
                                obj.value = 'No'
                            }
                            if (!isHeader){
                                fields.push(obj)
                            } else {
                                headerObj.children.push(obj)
                            }
                        } else if (field.type == 'file') {
                            obj.id = field.id.toString()
                            obj.type = field.type
                            obj.label = field.label
                            let find = data.find(x => x.field && x.field.id && x.field.id == field.id)
                            if (find) {
                                obj.fileName = find.label
                                obj.value = find.value
								if (!obj.value.includes('https')) obj.value = 'https://' + obj.value
                            }
                            if (!isHeader){
                                fields.push(obj)
                            } else {
                                headerObj.children.push(obj)
                            }
                        } else if (field.type == 'checkbox') {
							if (field.multiple){
								obj.id = field.id.toString()
								obj.type = field.type
								obj.label = field.label
								let findAll = data.filter(x => x.field && x.field.id && x.field.id == field.id)
								let listValue = []
								findAll.forEach(x => {
									if (!x.value.includes('other')){
										let split = x.value.split(',')
										listValue = listValue.concat(split)
									} else {
										let newValue = ''
										if (x.value.includes(',other')){
											newValue = x.value.replace(',other','')
										} else {
											newValue = x.value.replace('other','')
										}
										let split = newValue.split(',')
										listValue = listValue.concat(split)
									}
								})
								listValue = listValue.filter(x => x != '').join(', ')
								obj.value = listValue

								if (!isHeader){
									fields.push(obj)
								} else {
									headerObj.children.push(obj)
								}
							} else {
								obj.id = field.id.toString()
								obj.type = field.type
								obj.label = field.label
								let find = data.find(x => x.field && x.field.id && x.field.id == field.id)
								if (find && find.value == 'on') {
									obj.value = 'True'
								} else if (find) {
									obj.value = 'False'
								}
								if (!isHeader){
									fields.push(obj)
								} else {
									headerObj.children.push(obj)
								}
							}
                        } else {
							console.log('FIELD CHECK', field)
                            obj.id = field.id.toString()
                            obj.type = field.type
                            let find = data.find(x => x.field && x.field.id && x.field.id == field.id)
                            if (find) {
                                obj.label = find.label
                                obj.value = find.value
                            }
							console.log('OBJECT VALUE', obj.value)
							if (obj.value == 'other'){
								let findOther = data.find(x => x.field && x.field.id && x.field.id == field.id && x.value != 'other')
								console.log('FIND OTHER', findOther)
								obj.value = findOther.value
							}
                            if (!isHeader){
                                fields.push(obj)
                            } else {
                                headerObj.children.push(obj)
                            }
                        }
                    }
                } else {
                    if (!isHeader) {
                        isHeader = true
                        headerObj = JSON.parse(JSON.stringify(field))
                        headerObj.children = []
                    } else {
                        let newObj = JSON.parse(JSON.stringify(headerObj))
                        fields.push(newObj)
                        headerObj = JSON.parse(JSON.stringify(field))
                        headerObj.children = []
                    }
                }
            })
            if (isHeader){
                fields.push(headerObj)
            }
            console.log('fieldssss', fields)
            return fields
        },
        findField(id) {
            let obj = {}
            if (Object.keys(this.previewForm).length != 0 && this.previewForm.data && this.previewForm.form) {
                if (id == 'firstName') {
                    let find = this.previewForm.data.fields.find(x => x.key == 'firstName')
                    if(find) obj = find
                }
                if (id == 'lastName') {
                    let find = this.previewForm.data.fields.find(x => x.key == 'lastName')
                    if(find) obj = find
                }
                if(id == 'email') {
                    let find = this.previewForm.data.fields.find(x => x.key == 'email')
                    if(find) obj = find
                }
                if(id == 'phone') {
                    let find = this.previewForm.data.fields.find(x => x.key == 'phone')
                    if(find) obj = find
                } else {
                    let find = this.previewForm.data.fields.find(x => {
                        if (x.field && x.field.id == id) return x
                    })
                    if(find) obj = find
                }
            }
            return obj
        },
        filter(array) {
            let arr = array.filter(x => x.key != 'url')
            return arr
        },
        closeModal() {
            this.visible = false
            this.previewForm = {}
        },
        openForm(form) {
            console.log('forms',form)
            this.previewForm = form
            this.visible = true
        },
        getDateString(string) {
            if (string != '' && string != undefined) {
                let year = string.substring(0,4)
                let month = string.substring(5,7)
                let day = string.substring(8,10)
				if (this.dateFormat == 'YYYY/MM/DD') return `${year}/${month}/${day}`
				else if (this.dateFormat == 'DD/MM/YYYY') return `${day}/${month}/${year}`
				else {
					return `${month}/${day}/${year}`
				}
            } else {return ''}
        },
    },
    created() {
        console.log('contact and id', this.contact, this.id)
        if (this.id != '' && (!this.contact || this.contact == undefined)) {
            this.loading = true
            this.$api.get(`/form-submits/:instance?contact=${this.id}`).then(({data}) => {
                this.forms = data
                this.loading = false
            }).catch(err => {
				this.loading = false;
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        } else if ((this.id == '' || !this.id || this.id == undefined) && this.contact && Object.keys(this.contact).length != 0) {
            this.loading = true
            this.$api.get(`/form-submits/:instance?contact=${this.contact.id}`).then(({data}) => {
                this.forms = data
                this.loading = false
            }).catch(err => {
				this.loading = false;
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        }
    }
}
</script>
<style scoped>
.action-icons:hover{
    color:var(--orange)
}
.headerLabel{
    color:#000;
}
</style>
<style>
.smallIcon {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    text-align: center;
    padding: 7px 0;
}
</style>
