<template>
    <div>
        <BHLoading :show="loading"/>
        <a-form-model :model="contact" ref="contact">
            <a-card>
                <div slot="title">
                    <a-row :gutter="16">
                        <a-col :span="8">
                            <a-form-model-item label="Lead Status" prop="leadStatus">
                                <a-select v-model="contact.leadStatus" placeholder="Select one" size="large">
                                    <div slot="dropdownRender" slot-scope="menu">
                                        <v-nodes :vnodes="menu" />
                                        <a-divider style="margin: 4px 0;" />
                                        <div
                                            style="padding: 8px; cursor: pointer; color:var(--orange)"
                                            @mousedown="e => e.preventDefault()"
                                            @click="addNewStatus"
                                        >
                                            <i style="font-size:15px;" class="fe fe-edit-2 mr-2" /> Add New Lead Status
                                        </div>
                                    </div>
                                    <a-select-option v-for="stat in Object.values(leadStatus)" :key="stat.id" :value="stat.id">{{stat.name}}</a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-model-item label="Source" prop="source">
                                <a-select v-model="contact.source" placeholder="Select one" size="large">
                                    <div slot="dropdownRender" slot-scope="menu">
                                        <v-nodes :vnodes="menu" />
                                        <a-divider style="margin: 4px 0;" />
                                        <div
                                            style="padding: 8px; cursor: pointer; color:var(--orange)"
                                            @mousedown="e => e.preventDefault()"
                                            @click="addNewSource"
                                        >
                                            <i style="font-size:15px;" class="fe fe-edit-2 mr-2" /> Add New Source
                                        </div>
                                    </div>
                                    <a-select-option v-for="source in sources" :key="source.id" :value="source.id">{{source.name}}</a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
						<a-col :span="8">
							<a-form-model-item label="Source URL" prop="sourceUrl">
								<a-input size="large" placeholder="Enter Source URL" v-model="contact.sourceUrl" disabled/>
							</a-form-model-item>
	                  	</a-col>
                    </a-row>
                </div>
                <a-row :gutter="16">
                    <a-col :span="8">
                        <a-form-model-item label="First Name" prop="firstName" required :rules="req('Please input a first name')">
                            <a-input size="large" placeholder="Enter First Name" v-model="contact.firstName" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item label="Middle Name" prop="middleName">
                            <a-input size="large" placeholder="Enter Middle Name" v-model="contact.middleName" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item label="Last Name" prop="lastName" required :rules="req('Please input a last name')">
                            <a-input size="large" placeholder="Enter Last Name" v-model="contact.lastName" />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="6">
                        <a-form-model-item label="Prefix" prop="prefix">
                            <a-input size="large" placeholder="Enter Prefix" v-model="contact.prefix" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-model-item label="Suffix" prop="suffix">
                            <a-input size="large" placeholder="Enter Suffix" v-model="contact.suffix" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="Job Title" prop="jobTitle">
                            <a-input size="large" placeholder="Enter Job Title" v-model="contact.jobTitle" />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="8">
                        <a-form-model-item label="Email" prop="email" required :rules="req('Please enter an email')">
                            <a-input size="large" placeholder="Enter Email" v-model="contact.email" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item label="Phone" prop="phone" required :rules="req('Please enter a phone number')">
                            <a-input size="large" placeholder="Enter Phone Number" v-model="contact.phone" />
                        </a-form-model-item>
                    </a-col>
					<a-col :span="8">
                        <a-form-model-item label="Company" prop="company">
                            <a-input size="large" placeholder="Enter Company" v-model="contact.company" />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-model-item label="Address" prop="address">
                            <a-input size="large" v-model="contact.address" placeholder="Street" />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-model-item prop="city">
                            <a-input size="large" v-model="contact.city" placeholder="City" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-model-item prop="region">
                            <a-input size="large" v-model="contact.region" placeholder="State / Province" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-model-item prop="postal">
                            <a-input size="large" v-model="contact.postal" placeholder="Zip Code / Postal Code" />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-model-item prop="country">
                            <a-select v-model="contact.country" placeholder="Select a country" size="large">
                                <a-select-option value="Canada">Canada</a-select-option>
                                <a-select-option value="USA">United States</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-model-item label="Tags" prop="tags">
                            <a-select v-model="contact.tags" mode="tags" style="width: 100%" placeholder="Add Tags" size="large">
                                <a-select-option v-for="(tag,tagI) in tags" :value="tag.name" :key="tag+tagI">
                                    {{tag.name}}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-model-item label="Description" prop="description">
                            <a-textarea
                                size="large"
                                v-model="contact.description"
                                :auto-size="{ minRows: 3, maxRows: 5 }"
                                placeholder="Add description"
                                />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-model-item label="Visibility" prop="visibility">
                            <a-select @change="changeVisibility" v-model="contact.visibility" style="width: 100%;" size="large">
                                <a-select-option value="everyone">
                                    Everyone
                                </a-select-option>
                                <a-select-option value="owners">
                                    Only Sales Owner(s)
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-model-item label="Sales Owner" prop="owners" :rules="contact.visibility == 'owners' ? req('Please select one') : {required:false, message:''}" >
                            <a-select v-model="contact.owners" placeholder="Select one" size="large" mode="multiple">
                                <a-select-option v-for="user in users" :key="user.id" :value="user.id">{{`${user.firstName} ${user.lastName}`}}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <hr style="margin-left: -24px; margin-right: -24px">
                <a-row :gutter="16" v-if="contact.others && contact.others.customFields">
                    <a-col :span="24" v-for="field in Object.values(contact.others.customFields)" :key="field.id" :value="field.id">
                        <a-form-model-item :label="field.name">
                            <a-input v-if="field.type == 'number' || field.type == 'text'" v-model="field.options.default" />
                            <template v-else-if="field.type == 'checkbox'">
                                <a-checkbox v-for="(ans, ansI) in field.options.multipleChoices" :value="ansI" :key="ansI" @change="(e) => changeCheck(field.id, e)" :checked="ansI == field.options.default">{{ans}}</a-checkbox>
                            </template>
                            <template v-else-if="field.type == 'multiplechoice'">
                                <a-select v-model="field.options.default" :mode="field.options.multipleAnswers ? 'multiple' : 'default'">
                                    <a-select-option v-for="(ans, ansI) in field.options.multipleChoices" :key="ansI" :value="ansI">{{ans}}</a-select-option>
                                </a-select>
                            </template>
                            <template v-else-if="field.type == 'date'">
                                <a-date-picker style="width:100%" v-model="field.options.default" />
                            </template>
                        </a-form-model-item>
                    </a-col>
            </a-row>
                <a-button @click="$store.commit('OPEN_LEAD_STATUS', {type:'customField'})" style="color:var(--orange); border-color:var(--orange)"><a-icon type="plus" />ADD CUSTOM FIELD</a-button>
                <div class="dF jE">
                    <a-button class="mr-3 cancel-button" size="large" style="width:150px" @click="cancel">CANCEL</a-button>
                    <a-button style="width:150px" type="primary" size="large" @click="submit">SAVE</a-button>
                </div>
            </a-card>
        </a-form-model>
    </div>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'
import moment from 'moment'
export default {
    components:{
        VNodes: {
        functional: true,
        render: (h, ctx) => ctx.props.vnodes,
        },
        BHLoading
    },
    data() {
        return {
            dateFormat:null,
            loading:false,
            contact:{},
            social: [
                'LinkedIn',
                'Twitter',
                'Google+',
                'Facebook',
                'YouTube',
                'Instagram',
                'Pinterest',
                'WeChat',
                'Quora',
                'Foursquare',
                'Klout',
                'Gravatar',
                'Other'
            ],
        }
    },
    watch:{
        customFields:{
            handler(val) {
                if (!this.contact.others) {
                    let fields = {}
                    JSON.parse(JSON.stringify(Object.values(val))).forEach(field => {
                        fields[field.id] = field
                        if (fields[field.id].type == 'date' && fields[field.id].options) {
                            fields[field.id].options.default = moment(new Date(fields[field.id].options.default))
                        }
                    })
                    let others = {}
                    others.customFields = fields
                    this.contact.others = others
                } else if (this.contact.others && !this.contact.others.customFields) {
                    let fields = {}
                    JSON.parse(JSON.stringify(Object.values(val))).forEach(field => {
                        fields[field.id] = field
                        if (fields[field.id].type == 'date' && fields[field.id].options) {
                            fields[field.id].options.default = moment(new Date(fields[field.id].options.default))
                        }
                    })
                    this.contact.others.customFields = fields
                } else if (this.contact.others && this.contact.others.customFields) {
                    let fields = {}
                    JSON.parse(JSON.stringify(Object.values(val))).forEach(field => {
                        if (this.contact.others.customFields[field.id]) {
                            fields[field.id] = this.contact.others.customFields[field.id]
                        } else {
                            fields[field.id] = field
                        }

						if (fields[field.id].type == 'date' && fields[field.id].options) {
                            fields[field.id].options.default = moment(new Date(fields[field.id].options.default))
                        }
                    })
                    this.contact.others.customFields = fields
                }
            }
        },
        '$route':{
            immediate:true,
            async handler(val){
                if (val.params && val.params.id && val.params.id != '') {
                    let obj = {}
                    if (this.$store.state.contacts.allContacts[val.params.id]) obj = JSON.parse(JSON.stringify(this.$store.state.contacts.allContacts[val.params.id]))
                    else if(this.$store.state.contacts.externalContacts[val.params.id]) obj = JSON.parse(JSON.stringify(this.$store.state.contacts.externalContacts[val.params.id]))
                    else {
                        await this.$api.get(`/contacts/:instance/${val.params.id}`).then( ({data}) => {
                            this.$store.commit('ADD_EXTERNAL_CONTACT', data)
                            obj = JSON.parse(JSON.stringify(this.$store.state.contacts.externalContacts[val.params.id]))
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    }
                    if (obj.social && obj.social.length) {
                        obj.social.forEach((soc, socI) => {
                            if (soc == null) obj.social.splice(socI,1)
                        })
                    }
                    if (obj.leadStatus == null) {
                        obj.leadStatus = ''
                    } else if(typeof obj.leadStatus == 'object') {
                        obj.leadStatus = obj.leadStatus.id
                        if (!this.leadStatus[obj.leadStatus]) obj.leadStatus = ''
                    } else if (typeof obj.leadStatus == 'string') {
                        if (!this.leadStatus[obj.leadStatus]) obj.leadStatus = ''
                    }
                    if (!obj.owners) obj.owners = []
                    else if (obj.owners && typeof obj.owners[0] == 'object') obj.owners = obj.owners.map(x => x = x.id)
                    if (obj.tags && obj.tags.length) {
                        console.log('tagssss ',obj.tags)
                        let tags = []
                        obj.tags.forEach(tag => {
                            if (tag.id) tag = tag.id
                            if (this.tags[tag]) {
                                tags.push(this.tags[tag].name)
                            }
                        })
                        obj.tags = tags
                    }
                    if (!obj.others) {
                        let fields = {}
                        JSON.parse(JSON.stringify(Object.values(this.customFields))).forEach(field => {
                            fields[field.id] = field
                            if (fields[field.id].type == 'date') {
                                fields[field.id].options.default = fields[field.id].options.default ? moment(new Date(fields[field.id].options.default)) : null;
                            }
                        })
                        let others = {}
                        others.customFields = fields
                        obj.others = others
                    } else if ((obj.others && !obj.others.customFields) || (obj.others && !obj.others.hasOwnProperty('customFields'))) {
                        let fields = {}
                        JSON.parse(JSON.stringify(Object.values(this.customFields))).forEach(field => {
                            fields[field.id] = field
                            if (fields[field.id].type == 'date') {
                                fields[field.id].options.default = fields[field.id].options.default ? moment(new Date(fields[field.id].options.default)) : null
                            }
                        })
                        obj.others.customFields = fields
                    } else if (obj.others && obj.others.customFields) {
						let fields = {}
						JSON.parse(JSON.stringify(Object.values(this.customFields))).forEach(field => {
							if (obj.others.customFields[field.id]) {
								if (obj.others.customFields[field.id].hasOwnProperty('value')) {
									fields[field.id] = field
									fields[field.id].options.default = obj.others.customFields[field.id].value
									if (fields[field.id].type == 'date') {
										fields[field.id].options.default = fields[field.id].options.default ? isNaN(Number(fields[field.id].options.default)) ? fields[field.id].options.default : Number(fields[field.id].options.default) : null
									} else if (fields[field.id].type == 'checkbox') {
										const index = field.options.multipleChoices.findIndex(c => c == fields[field.id].options.default)
										if (index > -1) {
											fields[field.id].options.default = index
										}
									} else if (fields[field.id].type == 'multiplechoice') {
										if (field.options.multipleAnswers) {
											let values = [];
											fields[field.id].options.default = fields[field.id].options.default.split(',').map(c => c.trim())
											field.options.multipleChoices.forEach((c, index) => {
												if (fields[field.id].options.default.includes(c)) {
													values.push(index)
												}
											})
											if (values.length > 0) {
												fields[field.id].options.default = values
											}
										} else {
											const index = field.options.multipleChoices.findIndex(c => c == fields[field.id].options.default)
											if (index > -1) {
												fields[field.id].options.default = [index]
											}
										}
									}
								} else {
									fields[field.id] = obj.others.customFields[field.id]
								}
							} else {
								fields[field.id] = field
							}

							if (fields[field.id].type == 'date') {
								fields[field.id].options.default = fields[field.id].options.default ? moment(new Date(fields[field.id].options.default)) : null
							}
						})
						obj.others.customFields = fields
					}
                    obj.owners = obj.owners.filter(id => this.validUser.includes(id))
                    this.contact = obj
					if(!this.contact.leadStatus && this.newLeadStatusId) {
						this.contact.leadStatus = this.newLeadStatusId
					}
                }
            }
        },
    },
    props:{

    },
    computed:{
        sources() {
            if (this.$store.state.contacts?.allSettings?.app?.options?.leadSource?.length) {
                return this.$store.state.contacts.allSettings.app.options.leadSource
            } else {
                return [
                    {name:'No Source', id:'nosource'},
                    {name:'Email', id:'email'},
                    {name:'Cold Call', id:'coldcall'},
                    {name:'Advertising', id:'advertising'}
                ]
            }
        },
        validUser() {
            return this.users.map(x => x.id)
        },
        customFields() {
            return this.$store.state.contacts.fields || {}
        },
        tags(){
          return this.$store.state.contacts.tags
        },
        leadStatus() {
            return this.$store.state.contacts.statuses
        },
        users() {
            return this.$store.state.contacts.users
        },
        owner() {
            return this.$store.state.user.user
        },
		newLeadStatusId(){
			const found = Object.values(this.leadStatus).filter(ls => ls.name === 'New');
			return found ? found.id : null;
		}
    },
    methods:{
        moment,
        changeCheck(id, obj) {
            let find = Object.values(this.contact.others.customFields).find(x => x.id == id)
            if (find && obj.target.checked) {
                find.options.default = obj.target.value
            }
        },
        cancel() {
            if (this.$route.params && this.$route.params.id && this.$route.params.id != '' && this.$store.state.contacts.allContacts[this.$route.params.id]) {
                let obj = {}
                obj = JSON.parse(JSON.stringify(this.$store.state.contacts.allContacts[this.$route.params.id]))
                if (obj.leadStatus == null) {
                    obj.leadStatus = ''
                } else if (typeof obj.leadStatus == 'object') {
                    obj.leadStatus = obj.leadStatus.id
                }
                if (!obj.owners) obj.owners = []
                else if (obj.owners && typeof obj.owners[0] == 'object') obj.owners = obj.owners.map(x => x = x.id)
                if (obj.tags && obj.tags.length) {
                    let tags = []
                    obj.tags.forEach(tag => {
                        if (this.tags[tag]) {
                            tags.push(this.tags[tag].name)
                        }
                    })
                    obj.tags = tags
                }
                if(obj.others && obj.others.customFields) {
                    let fields = {}
                    Object.keys(this.customFields).forEach(id => {
                        if (obj.others.customFields[id]) {
                            fields[id] = obj.others.customFields[id]
                            if (fields[id].type == 'date') {
                                fields[id].options.default = moment(new Date(fields[id].options.default))
                            }
                        } else {
                            fields[id] = this.customFields[id]
                            if (fields[id].type == 'date') {
                                fields[id].options.default = moment(new Date(fields[id].options.default))
                            }
                        }
                    })
                    obj.others.customFields = fields
                } else if (!obj.other || !obj.other.customFields) {
                    let fields = {}
                    JSON.parse(JSON.stringify(Object.values(this.customFields))).forEach(field => {
                        fields[field.id] = field
                        if (fields[field.id].type == 'date') {
                            fields[field.id].options.default = moment(new Date(fields[field.id].options.default))
                        }
                    })
					if(!obj.others) {
						obj.others = {}
					}
                    obj.others.customFields = fields
                }
                this.contact = obj
            }
			this.$router.go(-1);
        },
        tagsToIds(obj){

            let tags = obj.tags || []

            obj.newTags = []
            let oldTags = []

            let self = this

            tags.forEach(tID => {
            if (Object.values(self.tags).find(x => x.name === tID)) {
                let find = Object.values(self.tags).find(x => x.name == tID)
                oldTags.push(find.id)
            }
            else obj.newTags.push(tID)
            })
            obj.tags = oldTags

            return obj

        },
        submit() {
            this.$refs.contact.validate(async valid => {
                if (valid) {
                    let sendObj = JSON.parse(JSON.stringify(this.contact))
					delete sendObj.sourceUrl;
                    sendObj = this.tagsToIds(sendObj)
                    if (sendObj.others && sendObj.others.customFields) {
                        Object.keys(sendObj.others.customFields).forEach(key => {
                            if (sendObj.others.customFields[key].type == 'date' ) {
                                sendObj.others.customFields[key].options.default = moment(sendObj.others.customFields[key].options.default).format('X')*1000
                            }
                        })
                    }
                    if (sendObj.leadStatus == '') sendObj.leadStatus = null
                    if (sendObj.notes && sendObj.notes.length && typeof sendObj.notes[0] == 'object') sendObj.notes = sendObj.notes.map(x => x = x.id)
                    this.loading=true
                    this.$api.put(`/contacts/:instance/${sendObj.id}`,sendObj).then( ({data}) => {
                        this.$store.commit('SET_PROP', {where:['allContacts',data.id],what:data} )
                        this.$store.commit('SET_PROP', {where:['externalContacts',data.id],what:data} )

                        this.$notification['success']({
                            message: 'Update Success',
                            description: 'Your contact has been updated successfully.',
                            duration: 4
                        });
                    }).catch( err => {
                        this.$message.error(this.$err(err))
                    }).finally(() => { this.loading=false })
                } else {
                    console.error('Invalid form details')
                }
            })
        },
        req:msg=>({required:true,message:msg}),
        addNewStatus() {
            this.$store.commit('OPEN_LEAD_STATUS', {type:'leadStatus'})
        },
        addNewSource() {
            this.$store.commit('OPEN_LEAD_STATUS', {type:'leadSource'})
        },
        changeVisibility(e) {
            // if (e == 'owners') {
            //     this.contact.owners = this.users.map(x => x = x.id)
            // } else if (e == 'everyone') {
            //     this.contact.owners = [this.owner.id]
            // }
        },
    }
}
</script>

<style lang="scss" scoped>
.popoverContent{
    height: 35px;
    width: 150px;
    line-height: 35px;
    padding-left: 10px;
    cursor: pointer;
}
.popoverContent:hover{
    background-color: var(--off-white-light);
    color:#000;
}
</style>
