<template>
    <!-- Expanded Details Contact Page -->
    <div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="overflow-y:scroll">
        <BHLoading :show="loading" />
        <div class="mb-3" style="display: inline-block;">
            <div style="display: inline-block; cursor: pointer;">
                <div @click="$router.push('/opportunities/sales')" class="dF">
                    <a-icon class="mr-4" style="font-size: 1.75rem;" type="arrow-left" />
                    <!-- <h3>{{opportunity.name}}</h3> -->
					<h3>Back to All Opportunities</h3>
                </div>
            </div>
        </div>

        <div class="dF">
            <div style="width:325px">
                <a-card class="mb-4">
                    <div class="dF aC jC">
                        <a-avatar style="background-color: #D5F2EA; padding: 5px 0;" shape="circle" :size="70"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 36.479 21.887" fill="#00c48c"> <path id="Icon_awesome-money-bill-alt" data-name="Icon awesome-money-bill-alt" class="cls-1" d="M20.063,17.267h-.912V12.252A.456.456,0,0,0,18.7,11.8h-.774a1.366,1.366,0,0,0-.759.23l-.874.583a.456.456,0,0,0-.127.632l.506.759a.456.456,0,0,0,.632.127l.027-.018v3.16h-.912a.456.456,0,0,0-.456.456v.912a.456.456,0,0,0,.456.456h3.648a.456.456,0,0,0,.456-.456v-.912A.456.456,0,0,0,20.063,17.267ZM34.655,4.5H1.824A1.824,1.824,0,0,0,0,6.324V24.563a1.824,1.824,0,0,0,1.824,1.824H34.655a1.824,1.824,0,0,0,1.824-1.824V6.324A1.824,1.824,0,0,0,34.655,4.5ZM2.736,23.651V20a3.648,3.648,0,0,1,3.648,3.648Zm0-12.767V7.236H6.384A3.648,3.648,0,0,1,2.736,10.884Zm15.5,10.944c-3.022,0-5.472-2.858-5.472-6.384s2.45-6.384,5.472-6.384,5.472,2.858,5.472,6.384S21.26,21.827,18.239,21.827Zm15.5,1.824H30.095A3.648,3.648,0,0,1,33.743,20Zm0-12.767a3.648,3.648,0,0,1-3.648-3.648h3.648Z" transform="translate(0 -4.5)"/> </svg></a-avatar>
                        <div class="ml-3" style="text-align: left;">
                            <h5 style="margin-bottom: 0; color:#000; display: flex;">
                                {{opportunity.name}}
                                <a-tooltip class="ml-2" v-if="opportunity.product && checkAvailability(opportunity)" overlayClassName="change-tooltip-color">
                                    <template slot="title">
                                        The product for this opportunity is no longer available.
                                    </template>
                                    <a-icon type="warning" style="color: var(--danger); font-size: 18px;" />
                                </a-tooltip>
                            </h5>
                            <div class="dF">
                                <div class="mr-2" style="font-size: 16px;color:#000">${{$formatNumber(opportunity.dealValue)}}</div>
                                |
                                <div class="ml-2" style="font-size: 16px; color: #9EA0A5;">{{opportunity.stage && opportunity.probability ? `${getStage(opportunity.stage)} ${opportunity.probability}%`:'0%'}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4" style="display: flex; justify-content: center;">
                        <h5 style="cursor:pointer" @click="opportunity.contact && opportunity.contact.id ? $router.push(`/leads/${opportunity.contact.id}`) : ''">{{opportunity.contact && opportunity.contact.firstName && opportunity.contact.lastName && opportunity.contact.firstName != '' && opportunity.contact.lastName != '' ?  opportunity.contact.firstName + ' ' + opportunity.contact.lastName : 'No Contact'}}</h5>
                        <div class="ml-4" v-if="opportunity.contact" style="color: var(--orange);">{{displayPhone(opportunity.contact.phone)}}</div>
                        <div class="ml-4" v-else style="color: var(--orange);">No Phone</div>
                    </div>
					<div style="display: flex; justify-content: center;" v-if="opportunity.worksheet && opportunity.worksheet.id" class="mt-2">
						<a :href="`https://worksheets.bildhive.${$tld}/edit/${opportunity.worksheet.id}`" target="_blank">
							<a-tag style="cursor: pointer;">
								Worksheet - {{ opportunity.worksheet.readableId }} <a-icon type="man" class="ml-1" />
							</a-tag>
						</a>
					</div>
                    <div class="dF jC aC mt-3">
                        <a-tooltip v-if="opportunity.contact && opportunity.contact.id" slot="extra" overlayClassName="change-tooltip-color">
                            <template slot="title">Email Lead</template>
                            <svg @click="emailContact(opportunity.contact.id)" style="cursor:pointer; color:#9EA0A5" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail action-button"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                        </a-tooltip>
                        <a-tooltip v-if="opportunity.stage != 'transaction'" slot="extra" overlayClassName="change-tooltip-color">
                            <template slot="title">Delete Opportunity</template>
                            <svg @click="deleteOpportunity(opportunity.id)" style="cursor:pointer;color:var(--danger) " xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 ml-4"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                        </a-tooltip>
                    </div>
                </a-card>
                <menuList :menuList="settingsMenu" @menuclick="showMenu" />
            </div>
            <div class="f1 ml-4">
                <div style="display:grid;">
                    <LeadTransactions :opportunity="opportunity" :id="opportunity.contact.id" :contact="opportunity.contact" v-if="opportunity.contact && opportunity.contact.email && opportunity.contact.email != ''" />
                </div>
                <div class="mt-4">
                    <OpportunityInformation :id="opportunity.id" v-if="activeTab == 0" @update="updateOpportunity" @updateNotes="updateNotes" />
                    <OpportunityConnection @update="updateConnection" :id="opportunity.id" v-if="activeTab == 1" />
                    <OpportunityNote :id="opportunity.id" v-if="activeTab == 2" />
                    <OpportunityTask :id="opportunity.id" v-if="activeTab == 3" />
                    <OpportunityAppointment :id="opportunity.id" v-if="activeTab == 4" />
                    <LeadEmail :email="opportunity.contact.email" :id="opportunity.contact.id" v-if="opportunity.contact && opportunity.contact.email && opportunity.contact.email != '' && activeTab == 5" />
                    <!-- <LeadTransactions :opportunity="opportunity" :id="opportunity.contact.id" :contact="opportunity.contact" v-if="activeTab == 6 && opportunity.contact && opportunity.contact.email && opportunity.contact.email != ''" /> -->
                    <LeadForm :contact="opportunity.contact" v-if="activeTab == 6 && opportunity.contact && opportunity.contact.email && opportunity.contact.email != ''" />
                    <LeadAttachment @update="update" :contact="opportunity.contact" v-if="activeTab == 7 && opportunity.contact && opportunity.contact.email && opportunity.contact.email != ''" />
                    <div v-if="activeTab == 8">
                        <a-card>
                            <div v-for="(date,dateI) in createDates" :key="date+dateI">
                                <h6 class="mb-4">{{textDate(date.date)}}</h6>
                                <a-timeline>
                                    <div class="dF" v-for="(d,i) in date.sorted" :key="d+i">
                                        <div class="mr-3" style="margin-top: -5px; color: #9EA0A5;">
                                            {{textTime(d)}}
                                        </div>
                                        <a-timeline-item>{{d.logNote}}</a-timeline-item>
                                    </div>
                                </a-timeline>
                            </div>
                        </a-card>
                    </div>
                    <!-- <LeadOpportunity :id="contact.id" v-if="activeTab == 1" />
                    <LeadNote :id="contact.id" v-if="activeTab == 2" />
                    <LeadTask :id="contact.id" v-if="activeTab == 3" />
                    <LeadAppointment :id="contact.id" v-if="activeTab == 4" /> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LeadTransactions from '@/components/contacts/LeadTransactions'
    import BHLoading from 'bh-mod/components/common/Loading'
    import menuList from '@/components/common/menuList'
    import moment from 'moment'
    import OpportunityInformation from '@/components/contacts/OpportunityInformation'
    import OpportunityConnection from '@/components/contacts/OpportunityConnection'
    import OpportunityNote from '@/components/contacts/OpportunityNote'
    import OpportunityTask from '@/components/contacts/OpportunityTask'
    import OpportunityAppointment from '@/components/contacts/OpportunityAppointment'
    import LeadForm from '@/components/contacts/LeadForm'
    import LeadEmail from '@/components/contacts/LeadEmail'
    import LeadAttachment from '@/components/contacts/LeadAttachment'

    export default {
        components:{
            LeadTransactions,BHLoading,menuList,OpportunityInformation,OpportunityConnection,OpportunityNote, OpportunityTask,OpportunityAppointment, LeadForm, LeadAttachment, LeadEmail
        },
        data() {
            return {
                opportunityTimeline:[],
                transactionTimeline:[],
                loading:false,
                refresh:0,
                opportunity:{},
                activeTab:0,
                forms:[],
                attachments:[]
            }
        },
        watch:{
            '$route':{
                immediate:true,
                async handler(val){
                    if (val.params && val.params.id && val.params.id != '' && this.opportunities.find(x => x.id == val.params.id)) {
                        this.opportunity = JSON.parse(JSON.stringify(this.opportunities.find(x => x.id == val.params.id)))
                        this.loading = true
                        this.$api.get(`/timelines/:instance/opportunities/${this.opportunity.id}`).then(({data}) => {
                            this.opportunityTimeline = data
                            this.loading = false
                        }).catch(err => {
							this.loading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                        if (this.opportunity.hasOwnProperty('transaction') && this.opportunity.transaction && this.opportunity.transaction != '') {
                            this.loading = true
                            this.$api.get(`/timelines/:instance/transactions/${this.opportunity.transaction}`).then(({data}) => {
                                this.transactionTimeline = data
                                this.loading = false
                            }).catch(err => {
								this.loading = false
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
                        }
                        if (this.opportunity.contact) {
                            let id = this.opportunity.contact
                            if (id.id) id = id.id
							if (!this.$store.state.contacts.allContacts[id] && !this.$store.state.contacts.externalContacts[id]) {
								const { data } = await this.$api.get(`/contacts/:instance/${id}`)
								this.$store.commit('ADD_EXTERNAL_CONTACT', data)
							}

                            this.loading = true
                            this.$api.get(`/form-submits/:instance?contact=${id}`).then(({data}) => {
                                this.forms = data
                                this.forms.forEach(form => {
                                    if (form.data && form.data.fields) {
                                        let files = form.data.fields.filter(x => {
                                            if (x.field && x.field.type && x.field.type == 'file' && x.value && x.value.includes('https')) return x
                                        })

                                        this.attachments = this.attachments.concat(files)
                                    }
                                })
                            }).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
                            if (this.allContacts[id] && this.allContacts[id].others && this.allContacts[id].others.attachments) {
                                this.attachments = this.attachments.concat(this.allContacts[id].others.attachments)
                            } else if (this.externalContacts[id] && this.externalContacts[id].others && this.externalContacts[id].others.attachments) {
                                this.attachments = this.attachments.concat(this.externalContacts[id].others.attachments)
                            }
                            this.loading = false
                        }
                    }
                    this.$store.commit('SIDEBAR_SELECTION', {value:'Sales Pipeline'})
                }
            },
        },
        computed:{
			dateFormat(){
				return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
			},
            origProducts(){
                return this.$store.state.products
            },
            allContacts() {
                return this.$store.state.contacts.allContacts
            },
            externalContacts() {
                return this.$store.state.contacts.externalContacts
            },
			nylasAccount() {
	            return this.$store.state.contacts.nylasAccount || {}
	        },
            transactions() {
                let transactions = []
                if (this.$store.state.contacts.transactions && this.opportunity.contact && this.opportunity.contact.email && this.opportunity.contact.email != '') {
                    transactions = this.$store.state.contacts.transactions.filter(x => {
                        if (x.purchasers) {
                            let emails = x.purchasers.map(y => y = y.email)
                            if (emails.includes(this.opportunity.contact.email)) return x
                        }
                    })
                }
                return transactions
            },
            events() {
                let events = []
                if (this.opportunity && this.opportunity.id) {
                    if (this.opportunity.updatedAt && this.opportunity.createdAt && parseInt(this.moment(this.opportunity.updatedAt).format('X')*1000) == parseInt(this.moment(this.opportunity.createdAt).format('X')*1000)) {
                        let obj = {}
                        obj.date = this.opportunity.createdAt
                        obj.logNote = 'This opportunity was added'
                        events.push(obj)
                    } else if (this.opportunity.updatedAt && this.opportunity.createdAt && parseInt(this.moment(this.opportunity.updatedAt).format('X')*1000) != parseInt(this.moment(this.opportunity.createdAt).format('X')*1000)){
                        let created = {}
                        created.date = this.opportunity.createdAt
                        created.logNote = 'This opportunity was added'
                        let updated = {}
                        updated.date = this.opportunity.updatedAt
                        updated.logNote = 'This opportunity was updated'
                        events.push(created)
                        events.push(updated)
                    }
                    this.tasks.forEach(task => {
                        if (task.opportunity && (task.opportunity.id == this.opportunity.id || task.opportunity == this.opportunity.id)) {
                            let obj = {}
                            obj.date = task.createdAt
                            obj.logNote = 'A new task was added for this opportunity'
                            events.push(obj)
                        }
                    })
                    if (this.opportunity.notes && this.opportunity.notes.length) {
                        this.opportunity.notes.forEach(note => {
                            let obj = {}
                            obj.date = note.createdAt
                            obj.logNote = 'A new note was added for this opportunity'
                            events.push(obj)
                        })
                    }
                    if (this.opportunityTimeline.length) {
                        this.opportunityTimeline.forEach(timeline => {
                            let obj = {}
                            obj.date = new Date(timeline.data.date).toISOString()
                            obj.logNote = timeline.data.logNote
                            events.push(obj)
                        })
                    }
                    if(this.transactionTimeline.length) {
                        this.transactionTimeline.forEach(timeline => {
                            let obj = {}
                            obj.date = timeline.createdAt
                            obj.logNote = timeline.data.action
                            events.push(obj)
                        })
                    }
                }
                return events
            },
            opportunities() {
                return this.$store.state.contacts.opportunities || []
            },
            createDates(){
                let dates = {}
                this.events.forEach( x => {
                    if (!dates.hasOwnProperty(`${x.date.substring(5,7)}` + '/' + `${x.date.substring(8,10)}`)){
                        let obj = {
                            date:`${x.date.substring(5,7)}` + '/' + `${x.date.substring(8,10)}` + '/' + `${x.date.substring(0,4)}`,
                            sorted:this.sortDates(`${x.date.substring(5,7)}` + '/' + `${x.date.substring(8,10)}` + '/' + `${x.date.substring(0,4)}`)
                        }
                        dates[obj.date] = obj
                    }
                })
                let sorted = Object.values(dates).sort( (a,b) => {
                    let {date:date1} = a
                    let {date:date2} = b

                    date1 = new Date(date1).getTime()
                    date2 = new Date(date2).getTime()

                    return date1 < date2 ? -1 : 1
                })

                return sorted
            },
            settingsMenu(){
                let items = [
                    {label: 'General Information', ant: 'info-circle'},
                    {label: `Secondary Contacts/Purchasers (${this.connectionCount})`, fa: 'users'},
                    {label: `Notes (${this.currentOpportunity.notes.length})`, fe: 'message'},
                    {label: `Tasks (${this.tasks.length})`, fe: 'user-check'},
                    {label: `Appointments (${this.appointments.length})`, ant: 'calendar'},
                    {label: `Email`, fa: 'envelope'},
                    {label: `Form Submissions (${this.forms.length})`, fe: 'align-justify'},
                    {label: `Attachments (${this.attachments.length})`, ant: 'file'},
                    {label: 'Timeline', ant: 'clock-circle'},
                ]
                return items
            },
			currentOpportunity() {
				return JSON.parse(JSON.stringify(this.opportunities.find(x => x.id == this.$route.params.id)))
			},
            noteCount() {
                let count = 0
                let time = this.refresh
                if (this.currentOpportunity.notes) {
                    count = this.currentOpportunity.notes.length
                }
                return count
            },
            connectionCount() {
                let count = 0
                let time = this.refresh
                if (this.currentOpportunity && this.currentOpportunity.id) {
                    let find = this.$store.state.contacts.opportunities.find(x => x.id == this.currentOpportunity.id)
                    count = find.secondaryContacts.length
                }
                return count
            },
            settings(){
                return this.$store.state.contacts.allSettings.app
            },
            showExpandOpportunity(){
                return this.$store.state.contacts.expandOpportunity.visible
            },
            expandOpportunity(){
                let expand = this.$store.state.contacts.expandOpportunity
                if (expand.opportunity && expand.opportunity.notes) {
                    expand.opportunity.notes.sort((a,b) => {
                        return (this.moment(b.updatedAt).format('X')*1000) - (this.moment(a.updatedAt).format('X')*1000)
                    })
                }
                return expand
            },
            contacts(){
                return Object.values(this.$store.state.contacts.allContacts)
            },
            sidebarSelection(){
                return this.$store.state.sidebarSelection
            },
            tags(){
                return this.$store.state.contacts.tags
            },
            tasks(){
                let tasks = JSON.parse(JSON.stringify(this.$store.state.contacts.tasks))
                if (tasks && tasks.length) tasks = tasks.filter( x => x.opportunity && x.relatedType == 'opportunity' && x.opportunity.id == this.opportunity.id)

                if (tasks && tasks.length){
                    tasks.sort((a,b) => {
                        return a.dueDate - b.dueDate
                    })
                }
                return tasks
            },
            appointments(){
                let refresh = this.test
                let appointments = JSON.parse(JSON.stringify(this.$store.state.contacts.appointments))
                if (appointments && appointments.length) appointments = appointments.filter( x => x.relatedType == 'opportunity' && x.opportunity && x.opportunity.id == this.opportunity.id)
                if (appointments && appointments.length){
                    appointments.sort((a,b) => {
                        return a.date - b.date
                    })
                }
                return appointments
            },
            currentUser(){
                return this.$store.state.user
            },
            searchOBJ(){
                return this.$store.state.contacts.searchOBJ
            },
            resultLength(){
                return this.$store.state.contacts.resultLength
            },
            instance(){
                return this.$store.state.instance
            },
            users(){
                return this.$store.state.contacts.users
            },
            query(){
                return this.$store.getters.query
            },
            loadingPage:{
                get(){
                    return this.$store.state.contacts.loadingPage
                },
                set(val){
                    this.$store.commit('LOAD_PAGE',val)
                }
            }
        },
        methods:{
            updateNotes(){
                this.opportunity = JSON.parse(JSON.stringify(this.opportunities.find(x => x.id == this.$route.params.id)))
            },
            checkAvailability(prod){
                let prodId = prod.product
                if (this.instance.productType == 'lowrise'){
                    let findProduct = this.origProducts.find(x => x.id == prodId)
                    if (findProduct){
                        return !['available', 'approved_for_reservation', 'reserved'].includes(findProduct.status);
                    } else {
                        return false
                    }
                } else if (this.instance.productType == 'highrise'){
                    let floorId = prod.floor
                    let findFloor = this.origProducts.find(x => x.id == floorId)
                    if (findFloor){
                        let findProduct = findFloor.units.find(x => x.id == prodId)
                        if (findProduct){
							return !['available', 'approved_for_reservation', 'reserved'].includes(findProduct.salesStatus)
                        } else {
                            return false
                        }
                    } else {
                        return false
                    }
                }
            },
            update(file) {
                this.attachments.push(file)
            },
            updateOpportunity(data) {
                this.opportunity = data
            },
            deleteOpportunity(id) {
                if (this.$p < 40){
                    return this.$message.error('You do not have permission to delete Opportunities')
                }
                let index = this.opportunities.findIndex(x => x.id == id)
                let name = ''
                if (index != -1) name = this.opportunities[index].name
                let self = this
                this.$confirm({
                    title: "Delete Opportunity",
                    content: h => <div>Do you want to delete <b>{name}</b>? All information related to them will be lost.</div>,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk() {
                        self.loading = true
                        self.$api.delete(`/opportunities/:instance/${id}`).then( ({data}) => {
                            self.loading = false
                            self.$store.commit('UPDATE_OPPORTUNITIES', data)
                            self.$router.push(`/opportunities/sales`)
                        }).catch(err => {
							self.loading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
                    },
                    onCancel() {
                        console.log('Cancel')
                    }
                })
            },
            emailContact(id) {
				if (this.nylasAccount && this.nylasAccount.email){
					this.$store.commit('ADD_NEW', 'Email')
					this.$store.commit('ADD_LEAD_ACTION', id)
				} else {
					this.$notification['error']({
						message: 'No Email Connection',
						description: 'You have not connected your mail account. To connect, go to Settings and select Email & Calendar Settings > Integrations.',
						duration: 5
					});
				}
            },
            getInactive(updatedAt) {
                let today = moment(new Date())
                let update = moment(updatedAt)
                return Math.round(today.diff(update, 'days', true))
            },
            updateConnection() {
                this.refresh = Date.now()
            },
            getStage(stageId) {
                if (this.$store.state.contacts.allSettings && this.$store.state.contacts.allSettings.app && this.$store.state.contacts.allSettings.app.options && this.$store.state.contacts.allSettings.app.options.stages && this.$store.state.contacts.allSettings.app.options.stages.list) {
                    let stages = this.$store.state.contacts.allSettings.app.options.stages.list
                    let find = stages.find(x => x.id == stageId)
                    if (find) {
                        return find.name
                    } else return ''
                }
            },
            moment,
            noteBy(user){
                let index = this.users.findIndex( x => x.id == user)
                return this.users[index].firstName[0].toUpperCase() + this.users[index].lastName[0].toUpperCase()
            },
            editNote(obj){
                let note = {}
                note = {
                    visible:true,
                    note:obj,
                    objId:this.expandOpportunity.opportunity.id,
                    type:'opportunity'
                }
                this.$store.commit('EDIT_NOTE',note)
            },
            convertDate(num){
                let x = new Date(num)
                //Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
                let year = x.getFullYear()
                let month = x.getMonth()+1
                let day = x.getDate()
				if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
				else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
				else {
					return month+'/'+day+'/'+year
				}
            },
            updatePerson(contact){
                this.expandedOpportunity.contact = contact
            },
            editTask(item) {
                let editTask = this.tasks.find(x => x.id == item.id)
                this.$store.commit('EDIT_TASK', editTask)
            },
            deleteTask(item) {
                let self = this
                if (this.$p < 40 && (!item.createdBy || item.createdBy.id !== this.$store.state.user.user.id)){
                    return this.$message.error('You do not have permission to delete Tasks')
                }
                this.$confirm({
                    title: "Delete",
                    content: h => <div>Do you want to delete this Task?</div>,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk() {
                        self.$api.delete(`/tasks/:instance/${item.id}`).then( ({data}) => {
                            self.$store.commit('DELETE_TASK', data)
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
                    },
                    onCancel() {
                        console.log('Cancel')
                    }
                })
            },
            editAppointment(item){
                console.log('EDIT APPOINTMENT CLICKED', item)
                this.$store.commit('EDIT_APPOINTMENT', item)
            },
            deleteAppointment(item){
                let self = this
                if (this.$p < 40 && (!item.createdBy || item.createdBy.id !== this.$store.state.user.user.id)){
                    return this.$message.error('You do not have permission to delete Appointments')
                }
                this.$confirm({
                    title: "Delete Appointment",
                    content: h => <div>Do you want to delete this Appointment?</div>,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk() {
                        self.$api.delete(`/appointments/:instance/${item.id}`).then( ({data}) => {
                            self.$store.commit('DELETE_APPOINTMENT', data)
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
                    },
                    onCancel() {
                        console.log('Cancel')
                    }
                })
            },
            updatedAppointment(){
                this.test = Date.now()
            },
            cancelEditAppointment(){
                this.$store.commit('CLOSE_APPOINTMENT')
            },
            cancelEdit(){
                this.$store.commit('CLOSE_EDIT_TASK')
            },
            textTime(date){
                let d = new Date(date.date)
                return d.toString().substring(16,21)
            },
            textDate(date){
                const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                let month = monthNames[Number(date.substring(0,2))-1]
                return month + ' ' + date.substring(3,5) + ', ' +  date.substring(6,10)
            },
            sortDates(date){
                let dates = []
                this.events.forEach( x => {
                    if (date == (`${x.date.substring(5,7)}` + '/' + `${x.date.substring(8,10)}` + '/' + `${x.date.substring(0,4)}`)){
                        dates.push(x)
                    }
                })
                dates.sort((a, b) => {
                    return new Date(a.date) - new Date(b.date)
                })
                return dates
            },
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
            searchAssign(input){
                if(input && input.length>1) {
                    this.users.forEach(x => {
                        x.name = x.firstName+ ' ' + x.lastName
                    })
                    this.userSource = this.users.filter(({name}) => {
                        name = name.toLowerCase()
                        input = input.toLowerCase()
                        return name.includes(input)
                    })
                } else {
                    return this.userSource = []
                }
            },
            saveInformation(){
                this.$refs.ruleForm.validate(async valid => {
                    if (valid) {
                        this.saveLoading = true
                        let sendObj = JSON.parse(JSON.stringify(this.expandedOpportunity))
                        sendObj.closeDate = this.moment(sendObj.closeDate).format('X')*1000
                        this.$api.put(`/opportunities/:instance/${sendObj.id}`, sendObj).then( ({data}) => {
                            let index = this.opportunities.findIndex(item => item.id == data.id)
                            this.opportunities[index] = data
                            this.saveLoading = false
                            this.$emit('closeexpand',data)
                            this.expandedOpportunity = {}
                        }).catch(err => {
							this.saveLoading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    } else {
                        return false;
                    }
                });
            },
            showMenu(item){
                this.activeTab = item
            },
            displayPhone(num){
				if(!num) return '';
                let phone = num.toString().trim().replace(/[^a-zA-Z0-9 ]/g, '')
                let first = phone.substring(0,3)
                let middle = phone.substring(3,6)
                let last = phone.substring(6,10)

                return '('+first+') '+middle+' '+last
            },

        },
    }
</script>


<style lang="scss" scoped>
    .smallIcon {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        text-align: center;
        padding: 7px 0;
    }
    .contacts-page{
        @media screen and (min-width:567px){
            margin-top:5%;
        }
    }
    .aB{
        align-items: baseline;
    }

    .edit-delete {
        padding: 9px 0;
		cursor: pointer;
		color: #9EA0A5;
		transition:color .1s ease-in;
	}
	.edit-delete:hover {
		color: orange;
		transition:color .1s ease-in;
	}
    .more-option-icon{
        width: 35px;
        height: 35px;
        border-radius: 35px;
        text-align: center;
        background-color: transparent;
    }
    .more-option-icon:hover{
        color: var(--orange) !important;
        cursor: pointer;
        background-color: var(--light-gray);
	}

    .popoverContent{
        height: 35px;
        width: 150px;
        line-height: 35px;
        padding-left: 10px;
    }
    .popoverContent:hover{
        background-color: var(--off-white-light);
        cursor: pointer;
    }
    .smallIcon {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        text-align: center;
        padding: 7px 0;
    }


</style>

<style lang="scss">
    .popoverStyle .ant-popover-inner-content {
        padding: 0;
        background-color: white;
        border-radius: 20px;
    }
    .card-headers .ant-card-body {
        padding: 15px 25px;
    }
    .taskParagraph p{
        margin-bottom: 0 !important;
    }
</style>
<style scoped>
    .action-button:hover{
        color:var(--orange) !important;
    }
    .display-card{
        border-radius: 3px;
        border-color:rgba(63,63,63,.15);
        -webkit-box-shadow: 0 1px 3px rgb(63 63 68 / 15%);
        border:none;
        background-color:#FFF;
        padding:24px;
    }
</style>
