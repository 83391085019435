<template>
    <div>
        <BHLoading :show="loading" />
        <EditTaskModal @updatetable="updateTable" @cancelEdit="cancelEdit" />

        <div class="dF" style="justify-content: space-between; align-items:center">
            <div><h4 class="mb-3 mt-3">{{sidebarSelection}}</h4></div>
            <div>
                <a-select v-model="sort" @change="selectSort" style="width:125px">
                    <a-icon slot="suffixIcon" type="caret-down" />
                    <a-select-option v-for="sort in sortType" :key="sort.value" :value="sort.value" class="text-dark"><strong>{{sort.label}}</strong></a-select-option>
                    <!-- <div style="background-color:white; border-radius:4px; padding:5px 20px 5px 20px">Sort by: <strong class="ml-3">{{sort}}</strong><a-icon class="ml-3" type="down" /> </div> -->
                </a-select>
            </div>
        </div>


        <!-- Sort By Due Date -->
        <div v-if="sort == 'dueDate'">

            <!-- Overdue Tasks -->
            <!-- <a-card v-if="overdueTasks.length != 0" class="mb-5" :bodyStyle="{padding:0}" title="Overdue Tasks"> -->
            <div v-if="overdueTasks.length != 0" style="background-color:white; overflow-x:scroll" class="hide-scrollbar mb-5">
                <div style="padding:16px 24px; font-size:17px; border-bottom:1px solid #EBEFF2">Overdue Tasks</div>
                <a-table :rowKey="(e) => e.id" class="white-table" :scroll="{ x: 1250 }" :columns="columns" :pagination="false" :dataSource="overdueTasks">

                    <div slot="name" slot-scope="obj" class="dF" style="color: #323C47;">
                        <div class="mr-3">
                            <a-checkbox class="checkbox-circle" :checked="obj.completed" :value="obj" @change="selectTask"></a-checkbox>
                        </div>
                        <div style="flex: 1;">
                            <div @click="editTask(obj)" style="cursor: pointer;">{{obj.title}}</div>
                        </div>
                    </div>
                    <div slot="description" slot-scope="obj">
                        <div class="reformat-description" v-html="obj.description"></div>
                    </div>

                    <div slot="contact" slot-scope="obj" class="dF" style="align-items:center">
                        <div class="mr-3">
                            <a-avatar v-if="obj.contact" style="color:white; background-color: #1070CA">
                                {{obj.contact.firstName && obj.contact.lastName && obj.contact.firstName.trim() && obj.contact.lastName.trim() ? obj.contact.firstName[0].toUpperCase() + obj.contact.lastName[0].toUpperCase():obj.contact.email[0].toUpperCase()}}
                            </a-avatar>
                            <a-avatar v-if="obj.opportunity" style="color:white; background-color: var(--green);">
                                {{obj.opportunity.name[0].toUpperCase()}}
                            </a-avatar>
                        </div>
                        <div @click="contactDetails(obj.contact)" v-if="obj.contact" style="cursor:pointer; color:var(--orange); text-decoration:underline">{{obj.contact.firstName && obj.contact.lastName && obj.contact.firstName.trim() && obj.contact.lastName.trim() ? obj.contact.firstName+' '+obj.contact.lastName : obj.contact.email}}</div>
                        <div @click="opportunityDetails(obj.opportunity)" v-if="obj.opportunity" style="cursor:pointer; color:var(--orange); text-decoration:underline">{{obj.opportunity.name}}</div>
                    </div>
                    <div slot="type" slot-scope="obj">{{getType(obj.type)}}</div>
                    <div slot="priority" slot-scope="obj">
                        <a-popover placement="bottom" trigger="click" overlayClassName="popoverStyle">
                            <template slot="content" >
                                <p class="popoverContent" @click="selectPriority('high',obj)" style="cursor:pointer; margin:0">HIGH PRIORITY</p>
                                <p class="popoverContent" @click="selectPriority('medium',obj)" style="cursor:pointer; margin:0">MED PRIORITY</p>
                                <p class="popoverContent" @click="selectPriority('low',obj)" style="cursor:pointer; margin:0">LOW PRIORITY</p>
                            </template>
                            <div v-if="obj.priority" class="priorityButton py-1 px-3 dF aC jSB" :style="obj.priority === 'high'? {'background-color':'var(--danger)'} : obj.priority === 'medium'? {'background-color':'var(--orange)'} : {'background-color':'var(--teal)'}">{{obj.priority.toUpperCase()+ ' '}}PRIORITY<a-icon class="ml-3" type="caret-down" /></div>
                        </a-popover>
                    </div>

                    <div slot="dateCreated" slot-scope="obj" style="color: #494A4E;">
                        {{numtoDate(obj.dueDate) == 'No Due Date' ? numtoDate(obj.dueDate) : `${numtoDate(obj.dueDate)}`}}
                    </div>

                    <div slot="action" slot-scope="obj" class="dF" style="justify-content: flex-end;">
                        <a-popover trigger="click" placement="bottomRight" overlayClassName="popoverStyle">
                            <div slot="content">
                                <div @click="viewTask(obj)" class="popoverContent"><a-icon type="eye" class="mr-3" /><div>Quick View</div></div>
                                <div @click="editTask(obj)" class="popoverContent"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-3"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg><div>Edit Task</div></div>
                                <div @click="copyTask(obj)" class="popoverContent"><i class="fe fe-copy mr-3" /><div>Copy Task</div></div>
                                <a-date-picker @change="onChange" v-model="dueDate">
                                    <span>
                                        <div @click="changeDate(obj)" class="popoverContent"><i class="fe fe-clock mr-3" />Change Due Date</div>
                                    </span>
                                </a-date-picker>
                                <div @click="deleteTask(obj)" class="popoverContent"><i style="color:#FD647C" class="fe fe-trash-2 mr-3" />Delete Task</div>

                            </div>
                            <div class="more-option-icon mr-4">
                                <a-icon style="line-height: 40px;" type="more" />
                            </div>
                        </a-popover>
                    </div>

                    <div slot="comment" slot-scope="obj" class="dF jC" style="color: #9EA0A5;">
                        <div class="mr-4 dF" style="align-items: center;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12.048 12.049" fill="#9ea0a5"> <path id="Icon_awesome-comment-alt" data-name="Icon awesome-comment-alt" class="cls-1" d="M10.542,0H1.506A1.507,1.507,0,0,0,0,1.506V8.283A1.507,1.507,0,0,0,1.506,9.789H3.765v1.977a.283.283,0,0,0,.449.228l2.939-2.2h3.388a1.507,1.507,0,0,0,1.506-1.506V1.506A1.507,1.507,0,0,0,10.542,0Z"/> </svg>
                            <div class="ml-2">
                                <div v-for="(task,taskI) in tasks" :key="task+taskI">
                                    <div v-if="task.id === obj.id">{{tasks[taskI].comments? tasks[taskI].comments.length:0}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="dF" style="align-items: center;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 13.563 15.5" fill="#9ea0a5"> <path id="Icon_awesome-paperclip" data-name="Icon awesome-paperclip" class="cls-1" d="M1.309,14.112a4.743,4.743,0,0,1,.042-6.587l6.35-6.5a3.388,3.388,0,0,1,4.864,0,3.524,3.524,0,0,1,0,4.913L7.03,11.6a2.259,2.259,0,0,1-3.269-.03A2.346,2.346,0,0,1,3.8,8.331L8.156,3.885a.484.484,0,0,1,.685-.007l.692.677a.484.484,0,0,1,.007.685L5.19,9.685a.409.409,0,0,0-.02.554.323.323,0,0,0,.475,0L11.18,4.587a1.586,1.586,0,0,0,0-2.2,1.452,1.452,0,0,0-2.094,0l-6.35,6.5A2.8,2.8,0,0,0,2.7,12.764a2.582,2.582,0,0,0,3.725.009l5.209-5.328a.484.484,0,0,1,.685-.008l.693.677a.484.484,0,0,1,.008.685L7.811,14.126a4.517,4.517,0,0,1-6.5-.015Z"/> </svg>
                            <div class="ml-2">
                                <div v-for="(task,taskI) in tasks" :key="task+taskI">
                                    <div v-if="task.id === obj.id">{{tasks[taskI].files? tasks[taskI].files.length:0}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div slot="assignTo" slot-scope="obj" class="dF">
                        <template v-for="(user,userI) in obj.assignTo">
                            <div v-if="userI < 3" :key="userI" class="owner-icon" :style="userI !== 0? 'margin-left: -5px' : ''">
                                <a-avatar v-if="user.avatar" :src="user.avatar" shape="circle" :size="30"/>
                                <a-avatar v-else>{{user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()}}</a-avatar>
                            </div>
                        </template>
                    </div>
                </a-table>
            </div>

            <!-- Today's Tasks -->
            <!-- <a-card v-if="todayTasks.length != 0" class="mb-5" :bodyStyle="{padding:0}" title="Today's Tasks"> -->
            <div v-if="todayTasks.length != 0" style="background-color:white; overflow-x:scroll" class="hide-scrollbar mb-5">
                <div style="padding:16px 24px; font-size:17px; border-bottom:1px solid #EBEFF2">Today's Tasks</div>
                <a-table :rowKey="(e) => e.id" class="white-table" :scroll="{ x: 1250 }" :columns="columns" :pagination="false" :dataSource="todayTasks">

                    <div slot="name" slot-scope="obj" class="dF" style="color: #323C47;">
                        <div class="mr-3">
                            <a-checkbox class="checkbox-circle" :checked="obj.completed" :value="obj" @change="selectTask"></a-checkbox>
                        </div>
                        <div style="flex: 1;">
                            <div @click="editTask(obj)" style="cursor: pointer;">{{obj.title}}</div>
                        </div>
                    </div>
                    <div slot="description" slot-scope="obj">
                        <div class="reformat-description" v-html="obj.description"></div>
                    </div>
                    <div slot="contact" slot-scope="obj" class="dF" style="align-items:center">
                        <div class="mr-3">
                            <a-avatar v-if="obj.contact" style="color:white; background-color: #1070CA">
                                {{obj.contact.firstName && obj.contact.lastName && obj.contact.firstName.trim() && obj.contact.lastName.trim() ? obj.contact.firstName[0].toUpperCase() + obj.contact.lastName[0].toUpperCase() : obj.contact.email[0].toUpperCase()}}
                            </a-avatar>
                            <a-avatar v-if="obj.opportunity" style="color:white; background-color: var(--green)">
                                {{obj.opportunity.name[0].toUpperCase()}}
                            </a-avatar>
                        </div>
                        <div @click="contactDetails(obj.contact)" v-if="obj.contact" style="cursor:pointer; color:var(--orange); text-decoration:underline">{{obj.contact.firstName && obj.contact.lastName && obj.contact.firstName.trim() && obj.contact.lastName.trim() ? obj.contact.firstName+' '+obj.contact.lastName : obj.contact.email}}</div>
                        <div @click="opportunityDetails(obj.opportunity)" v-if="obj.opportunity" style="cursor:pointer; color:var(--orange); text-decoration:underline">{{obj.opportunity.name}}</div>
                    </div>
                    <div slot="type" slot-scope="obj">{{getType(obj.type)}}</div>
                    <div slot="priority" slot-scope="obj">
                        <a-popover placement="bottom" trigger="click" overlayClassName="popoverStyle">
                            <template slot="content" >
                                <p class="popoverContent" @click="selectPriority('high',obj)" style="cursor:pointer; margin:0">HIGH PRIORITY</p>
                                <p class="popoverContent" @click="selectPriority('medium',obj)" style="cursor:pointer; margin:0">MED PRIORITY</p>
                                <p class="popoverContent" @click="selectPriority('low',obj)" style="cursor:pointer; margin:0">LOW PRIORITY</p>
                            </template>
                            <div v-if="obj.priority" class="priorityButton py-1 px-3 dF aC jSB" :style="obj.priority === 'high'? {'background-color':'var(--danger)'} : obj.priority === 'medium'? {'background-color':'var(--orange)'} : {'background-color':'var(--teal)'}">{{obj.priority.toUpperCase()+ ' '}}PRIORITY<a-icon class="ml-3" type="caret-down" /></div>
                        </a-popover>

                    </div>

                    <div slot="dateCreated" slot-scope="obj" style="color: #494A4E;">
                        {{numtoDate(obj.dueDate) == 'No Due Date' ? numtoDate(obj.dueDate) : `${numtoDate(obj.dueDate)}`}}
                    </div>

                    <div slot="action" slot-scope="obj" class="dF" style="justify-content: flex-end;">
                        <a-popover trigger="click" placement="bottomRight" overlayClassName="popoverStyle">
                            <div slot="content">
                                <div @click="viewTask(obj)" class="popoverContent"><a-icon type="eye" class="mr-3" /><div>Quick View</div></div>
                                <div @click="editTask(obj)" class="popoverContent"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-3"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>Edit Task</div>
                                <div @click="copyTask(obj)" class="popoverContent"><i class="fe fe-copy mr-3" />Copy Task</div>
                                <a-date-picker @change="onChange" v-model="dueDate">
                                    <span>
                                        <div @click="changeDate(obj)" class="popoverContent"><i class="fe fe-clock mr-3" />Change Due Date</div>
                                    </span>
                                </a-date-picker>
                                <div @click="deleteTask(obj)" class="popoverContent"><i style="color:#FD647C" class="fe fe-trash-2 mr-3" />Delete Task</div>

                            </div>
                            <div class="more-option-icon mr-4">
                                <a-icon style="line-height: 40px;" type="more" />
                            </div>
                        </a-popover>
                    </div>

                    <div slot="comment" slot-scope="obj" class="dF jC" style="color: #9EA0A5;">
                        <div class="mr-4 dF" style="align-items: center;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12.048 12.049" fill="#9ea0a5"> <path id="Icon_awesome-comment-alt" data-name="Icon awesome-comment-alt" class="cls-1" d="M10.542,0H1.506A1.507,1.507,0,0,0,0,1.506V8.283A1.507,1.507,0,0,0,1.506,9.789H3.765v1.977a.283.283,0,0,0,.449.228l2.939-2.2h3.388a1.507,1.507,0,0,0,1.506-1.506V1.506A1.507,1.507,0,0,0,10.542,0Z"/> </svg>
                            <div class="ml-2">
                                <div v-for="(task,taskI) in tasks" :key="task+taskI">
                                    <div v-if="task.id === obj.id">{{tasks[taskI].comments? tasks[taskI].comments.length:0}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="dF" style="align-items: center;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 13.563 15.5" fill="#9ea0a5"> <path id="Icon_awesome-paperclip" data-name="Icon awesome-paperclip" class="cls-1" d="M1.309,14.112a4.743,4.743,0,0,1,.042-6.587l6.35-6.5a3.388,3.388,0,0,1,4.864,0,3.524,3.524,0,0,1,0,4.913L7.03,11.6a2.259,2.259,0,0,1-3.269-.03A2.346,2.346,0,0,1,3.8,8.331L8.156,3.885a.484.484,0,0,1,.685-.007l.692.677a.484.484,0,0,1,.007.685L5.19,9.685a.409.409,0,0,0-.02.554.323.323,0,0,0,.475,0L11.18,4.587a1.586,1.586,0,0,0,0-2.2,1.452,1.452,0,0,0-2.094,0l-6.35,6.5A2.8,2.8,0,0,0,2.7,12.764a2.582,2.582,0,0,0,3.725.009l5.209-5.328a.484.484,0,0,1,.685-.008l.693.677a.484.484,0,0,1,.008.685L7.811,14.126a4.517,4.517,0,0,1-6.5-.015Z"/> </svg>
                            <div class="ml-2">
                                <div v-for="(task,taskI) in tasks" :key="task+taskI">
                                    <div v-if="task.id === obj.id">{{tasks[taskI].files? tasks[taskI].files.length:0}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div slot="assignTo" slot-scope="obj" class="dF">
                        <template v-for="(user,userI) in obj.assignTo">
                            <div v-if="userI < 3" :key="userI" class="owner-icon" :style="userI !== 0? 'margin-left: -5px' : ''">
                                <a-avatar v-if="user.avatar" :src="user.avatar" shape="circle" :size="30"/>
                                <a-avatar v-else>{{user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()}}</a-avatar>
                            </div>
                        </template>
                    </div>
                </a-table>
            </div>

            <!-- Tomorrow's Tasks -->
            <!-- <a-card v-if="tomorrowTasks.length != 0" class="mb-5" :bodyStyle="{padding:0}" title="Tomorrow's Tasks"> -->
            <div v-if="tomorrowTasks.length != 0" style="background-color:white; overflow-x:scroll" class="hide-scrollbar mb-5">
                <div style="padding:16px 24px; font-size:17px; border-bottom:1px solid #EBEFF2">Tomorrow's Tasks</div>
                <a-table :rowKey="(e) => e.id" class="white-table" :scroll="{ x: 1250 }" :columns="columns" :pagination="false" :dataSource="tomorrowTasks">

                    <div slot="name" slot-scope="obj" class="dF" style="color: #323C47;">
                        <div class="mr-3">
                            <a-checkbox class="checkbox-circle" :checked="obj.completed" :value="obj" @change="selectTask"></a-checkbox>
                        </div>
                        <div style="flex: 1;">
                            <div @click="editTask(obj)" style="cursor: pointer;">{{obj.title}}</div>
                        </div>
                    </div>
                    <div slot="description" slot-scope="obj">
                        <div class="reformat-description" v-html="obj.description"></div>
                    </div>
                    <div slot="contact" slot-scope="obj" class="dF" style="align-items:center">
                        <div class="mr-3">
                            <a-avatar v-if="obj.contact" style="color:white; background-color: #1070CA">
                                {{obj.contact.firstName && obj.contact.lastName && obj.contact.firstName.trim() && obj.contact.lastName.trim() ? obj.contact.firstName[0].toUpperCase() + obj.contact.lastName[0].toUpperCase() : obj.contact.email[0].toUpperCase()}}
                            </a-avatar>
                            <a-avatar v-if="obj.opportunity" style="color:white; background-color: var(--green)">
                                {{obj.opportunity.name[0].toUpperCase()}}
                            </a-avatar>
                        </div>
                        <div @click="contactDetails(obj.contact)" v-if="obj.contact" style="cursor:pointer; color:var(--orange); text-decoration:underline">{{obj.contact.firstName && obj.contact.lastName && obj.contact.firstName.trim() && obj.contact.lastName.trim() ? obj.contact.firstName+' '+obj.contact.lastName : obj.contact.email}}</div>
                        <div @click="opportunityDetails(obj.opportunity)" v-if="obj.opportunity" style="cursor:pointer; color:var(--orange); text-decoration:underline">{{obj.opportunity.name}}</div>
                    </div>
                    <div slot="type" slot-scope="obj">{{getType(obj.type)}}</div>
                    <div slot="priority" slot-scope="obj">
                        <a-popover placement="bottom" trigger="click" overlayClassName="popoverStyle">
                            <template slot="content" >
                                <p class="popoverContent" @click="selectPriority('high',obj)" style="cursor:pointer; margin:0">HIGH PRIORITY</p>
                                <p class="popoverContent" @click="selectPriority('medium',obj)" style="cursor:pointer; margin:0">MED PRIORITY</p>
                                <p class="popoverContent" @click="selectPriority('low',obj)" style="cursor:pointer; margin:0">LOW PRIORITY</p>
                            </template>
                            <div v-if="obj.priority" class="priorityButton py-1 px-3 dF aC jSB" :style="obj.priority === 'high'? {'background-color':'var(--danger)'} : obj.priority === 'medium'? {'background-color':'var(--orange)'} : {'background-color':'var(--teal)'}">{{obj.priority.toUpperCase()+ ' '}}PRIORITY<a-icon class="ml-3" type="caret-down" /></div>
                        </a-popover>

                    </div>

                    <div slot="dateCreated" slot-scope="obj" style="color: #494A4E;">
                        {{numtoDate(obj.dueDate) == 'No Due Date' ? numtoDate(obj.dueDate) : `${numtoDate(obj.dueDate)}`}}
                    </div>

                    <div slot="action" slot-scope="obj" class="dF" style="justify-content: flex-end;">
                        <a-popover trigger="click" placement="bottomRight" overlayClassName="popoverStyle">
                            <div slot="content">
                                <div @click="viewTask(obj)" class="popoverContent"><a-icon type="eye" class="mr-3" /><div>Quick View</div></div>
                                <div @click="editTask(obj)" class="popoverContent"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-3"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>Edit Task</div>
                                <div @click="copyTask(obj)" class="popoverContent"><i class="fe fe-copy mr-3" />Copy Task</div>
                                <a-date-picker @change="onChange" v-model="dueDate">
                                    <span>
                                        <div @click="changeDate(obj)" class="popoverContent"><i class="fe fe-clock mr-3" />Change Due Date</div>
                                    </span>
                                </a-date-picker>
                                <div @click="deleteTask(obj)" class="popoverContent"><i style="color:#FD647C" class="fe fe-trash-2 mr-3" />Delete Task</div>

                            </div>
                            <div class="more-option-icon mr-4">
                                <a-icon style="line-height: 40px;" type="more" />
                            </div>
                        </a-popover>
                    </div>

                    <div slot="comment" slot-scope="obj" class="dF jC" style="color: #9EA0A5;">
                        <div class="mr-4 dF" style="align-items: center;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12.048 12.049" fill="#9ea0a5"> <path id="Icon_awesome-comment-alt" data-name="Icon awesome-comment-alt" class="cls-1" d="M10.542,0H1.506A1.507,1.507,0,0,0,0,1.506V8.283A1.507,1.507,0,0,0,1.506,9.789H3.765v1.977a.283.283,0,0,0,.449.228l2.939-2.2h3.388a1.507,1.507,0,0,0,1.506-1.506V1.506A1.507,1.507,0,0,0,10.542,0Z"/> </svg>
                            <div class="ml-2">
                                <div v-for="(task,taskI) in tasks" :key="task+taskI">
                                    <div v-if="task.id === obj.id">{{tasks[taskI].comments? tasks[taskI].comments.length:0}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="dF" style="align-items: center;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 13.563 15.5" fill="#9ea0a5"> <path id="Icon_awesome-paperclip" data-name="Icon awesome-paperclip" class="cls-1" d="M1.309,14.112a4.743,4.743,0,0,1,.042-6.587l6.35-6.5a3.388,3.388,0,0,1,4.864,0,3.524,3.524,0,0,1,0,4.913L7.03,11.6a2.259,2.259,0,0,1-3.269-.03A2.346,2.346,0,0,1,3.8,8.331L8.156,3.885a.484.484,0,0,1,.685-.007l.692.677a.484.484,0,0,1,.007.685L5.19,9.685a.409.409,0,0,0-.02.554.323.323,0,0,0,.475,0L11.18,4.587a1.586,1.586,0,0,0,0-2.2,1.452,1.452,0,0,0-2.094,0l-6.35,6.5A2.8,2.8,0,0,0,2.7,12.764a2.582,2.582,0,0,0,3.725.009l5.209-5.328a.484.484,0,0,1,.685-.008l.693.677a.484.484,0,0,1,.008.685L7.811,14.126a4.517,4.517,0,0,1-6.5-.015Z"/> </svg>
                            <div class="ml-2">
                                <div v-for="(task,taskI) in tasks" :key="task+taskI">
                                    <div v-if="task.id === obj.id">{{tasks[taskI].files? tasks[taskI].files.length:0}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div slot="assignTo" slot-scope="obj" class="dF">
                        <template v-for="(user,userI) in obj.assignTo">
                            <div v-if="userI < 3" :key="userI" class="owner-icon" :style="userI !== 0? 'margin-left: -5px' : ''">
                                <a-avatar v-if="user.avatar" :src="user.avatar" shape="circle" :size="30"/>
                                <a-avatar v-else>{{user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()}}</a-avatar>
                            </div>
                        </template>
                    </div>
                </a-table>
            </div>

            <!-- Future Tasks -->
            <!-- <a-card v-if="futureTasks.length != 0" class="mb-5" :bodyStyle="{padding:0}" title="Future Tasks"> -->
            <div v-if="futureTasks.length != 0" style="background-color:white; overflow-x:scroll" class="hide-scrollbar mb-5">
                <div style="padding:16px 24px; font-size:17px; border-bottom:1px solid #EBEFF2">Future Tasks</div>
                <a-table :rowKey="(e) => e.id" class="white-table" :scroll="{ x: 1250 }" :columns="columns" :pagination="false" :dataSource="futureTasks">

                    <div slot="name" slot-scope="obj" class="dF" style="color: #323C47;">
                        <div class="mr-3">
                            <a-checkbox class="checkbox-circle" :checked="obj.completed" :value="obj" @change="selectTask"></a-checkbox>
                        </div>
                        <div style="flex: 1;">
                            <div @click="editTask(obj)" style="cursor: pointer;">{{obj.title}}</div>
                        </div>
                    </div>
                    <div slot="description" slot-scope="obj">
                        <div class="reformat-description" v-html="obj.description"></div>
                    </div>
                    <div slot="contact" slot-scope="obj" class="dF" style="align-items:center">
                        <div class="mr-3">
                            <a-avatar v-if="obj.contact" style="color:white; background-color: #1070CA">
                                {{obj.contact.firstName && obj.contact.lastName && obj.contact.firstName.trim() && obj.contact.lastName.trim() ? obj.contact.firstName[0].toUpperCase() + obj.contact.lastName[0].toUpperCase() : obj.contact.email[0].toUpperCase()}}
                            </a-avatar>
                            <a-avatar v-if="obj.opportunity" style="color:white; background-color: var(--green);">
                                {{obj.opportunity.name[0].toUpperCase()}}
                            </a-avatar>
                        </div>
                        <div @click="contactDetails(obj.contact)" v-if="obj.contact" style="cursor:pointer; color:var(--orange); text-decoration:underline">{{obj.contact.firstName && obj.contact.lastName && obj.contact.firstName.trim() && obj.contact.lastName.trim() ? obj.contact.firstName+' '+obj.contact.lastName : obj.contact.email}}</div>
                        <div @click="opportunityDetails(obj.opportunity)" v-if="obj.opportunity" style="cursor:pointer; color:var(--orange); text-decoration:underline">{{obj.opportunity.name}}</div>
                    </div>
                    <div slot="type" slot-scope="obj">{{getType(obj.type)}}</div>
                    <div slot="priority" slot-scope="obj">
                        <a-popover placement="bottom" trigger="click" overlayClassName="popoverStyle">
                            <template slot="content" >
                                <p class="popoverContent" @click="selectPriority('high',obj)" style="cursor:pointer; margin:0">HIGH PRIORITY</p>
                                <p class="popoverContent" @click="selectPriority('medium',obj)" style="cursor:pointer; margin:0">MED PRIORITY</p>
                                <p class="popoverContent" @click="selectPriority('low',obj)" style="cursor:pointer; margin:0">LOW PRIORITY</p>
                            </template>
                            <div v-if="obj.priority" class="priorityButton py-1 px-3 dF aC jSB" :style="obj.priority === 'high'? {'background-color':'var(--danger)'} : obj.priority === 'medium'? {'background-color':'var(--orange)'} : {'background-color':'var(--teal)'}">{{obj.priority.toUpperCase()+ ' '}}PRIORITY<a-icon class="ml-3" type="caret-down" /></div>
                        </a-popover>

                    </div>
                    <div slot="dateCreated" slot-scope="obj" style="color: #494A4E;">
                        {{numtoDate(obj.dueDate) == 'No Due Date' ? numtoDate(obj.dueDate) : `${numtoDate(obj.dueDate)}`}}
                    </div>

                    <div slot="action" slot-scope="obj" class="dF" style="justify-content: flex-end;">
                        <a-popover trigger="click" placement="bottomRight" overlayClassName="popoverStyle">
                            <div slot="content">
                                <div @click="viewTask(obj)" class="popoverContent"><a-icon type="eye" class="mr-3" /><div>Quick View</div></div>
                                <div @click="editTask(obj)" class="popoverContent"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-3"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>Edit Task</div>
                                <div @click="copyTask(obj)" class="popoverContent"><i class="fe fe-copy mr-3" />Copy Task</div>
                                <a-date-picker @change="onChange" v-model="dueDate">
                                    <span>
                                        <div @click="changeDate(obj)" class="popoverContent"><i class="fe fe-clock mr-3" />Change Due Date</div>
                                    </span>
                                </a-date-picker>
                                <div @click="deleteTask(obj)" class="popoverContent"><i style="color:#FD647C" class="fe fe-trash-2 mr-3" />Delete Task</div>

                            </div>
                            <div class="more-option-icon mr-4">
                                <a-icon style="line-height: 40px;" type="more" />
                            </div>
                        </a-popover>
                    </div>

                    <div slot="comment" slot-scope="obj" class="dF jC" style="color: #9EA0A5;">
                        <div class="mr-4 dF" style="align-items: center;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12.048 12.049" fill="#9ea0a5"> <path id="Icon_awesome-comment-alt" data-name="Icon awesome-comment-alt" class="cls-1" d="M10.542,0H1.506A1.507,1.507,0,0,0,0,1.506V8.283A1.507,1.507,0,0,0,1.506,9.789H3.765v1.977a.283.283,0,0,0,.449.228l2.939-2.2h3.388a1.507,1.507,0,0,0,1.506-1.506V1.506A1.507,1.507,0,0,0,10.542,0Z"/> </svg>
                            <div class="ml-2">
                                <div v-for="(task,taskI) in tasks" :key="task+taskI">
                                    <div v-if="task.id === obj.id">{{tasks[taskI].comments? tasks[taskI].comments.length:0}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="dF" style="align-items: center;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 13.563 15.5" fill="#9ea0a5"> <path id="Icon_awesome-paperclip" data-name="Icon awesome-paperclip" class="cls-1" d="M1.309,14.112a4.743,4.743,0,0,1,.042-6.587l6.35-6.5a3.388,3.388,0,0,1,4.864,0,3.524,3.524,0,0,1,0,4.913L7.03,11.6a2.259,2.259,0,0,1-3.269-.03A2.346,2.346,0,0,1,3.8,8.331L8.156,3.885a.484.484,0,0,1,.685-.007l.692.677a.484.484,0,0,1,.007.685L5.19,9.685a.409.409,0,0,0-.02.554.323.323,0,0,0,.475,0L11.18,4.587a1.586,1.586,0,0,0,0-2.2,1.452,1.452,0,0,0-2.094,0l-6.35,6.5A2.8,2.8,0,0,0,2.7,12.764a2.582,2.582,0,0,0,3.725.009l5.209-5.328a.484.484,0,0,1,.685-.008l.693.677a.484.484,0,0,1,.008.685L7.811,14.126a4.517,4.517,0,0,1-6.5-.015Z"/> </svg>
                            <div class="ml-2">
                                <div v-for="(task,taskI) in tasks" :key="task+taskI">
                                    <div v-if="task.id === obj.id">{{tasks[taskI].files? tasks[taskI].files.length:0}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div slot="assignTo" slot-scope="obj" class="dF">
                        <template v-for="(user,userI) in obj.assignTo">
                            <div v-if="userI < 3" :key="userI" class="owner-icon" :style="userI !== 0? 'margin-left: -5px' : ''">
                                <a-avatar v-if="user.avatar" :src="user.avatar" shape="circle" :size="30"/>
                                <a-avatar v-else>{{user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()}}</a-avatar>
                            </div>
                        </template>
                    </div>
                </a-table>
            </div>

            <!-- Tasks w/ No Due Date -->
            <!-- <a-card v-if="nodueTasks.length != 0" class="mb-5" title="Tasks without a due date"> -->
            <div v-if="nodueTasks.length != 0" style="background-color:white; overflow-x:scroll" class="hide-scrollbar">
                <div style="padding:16px 24px; font-size:17px; border-bottom:1px solid #EBEFF2">Tasks without a due date</div>
                <a-table :rowKey="(e) => e.id" class="white-table" :scroll="{ x: 1250 }" :columns="columns" :dataSource="nodueTasks" :pagination="false">

                    <div slot="name" slot-scope="obj" class="dF" style="color: #323C47;">
                        <div class="mr-3">
                            <a-checkbox class="checkbox-circle" :checked="obj.completed" :value="obj" @change="selectTask"></a-checkbox>
                        </div>
                        <div style="flex: 1;">
                            <div @click="editTask(obj)" style="cursor: pointer;">{{obj.title}}</div>
                        </div>
                    </div>
                    <div slot="description" slot-scope="obj">
                        <div class="reformat-description" v-html="obj.description"></div>
                    </div>
                    <div slot="contact" slot-scope="obj" class="dF" style="align-items:center">
                        <div class="mr-3">
                            <a-avatar v-if="obj.contact" style="color:white; background-color: #1070CA">
                                {{obj.contact.firstName && obj.contact.lastName && obj.contact.firstName.trim() && obj.contact.lastName.trim() ? obj.contact.firstName[0].toUpperCase() + obj.contact.lastName[0].toUpperCase() : obj.contact.email[0].toUpperCase()}}
                            </a-avatar>
                            <a-avatar v-if="obj.opportunity" style="color:white; background-color: var(--green)">
                                {{obj.opportunity.name[0].toUpperCase()}}
                            </a-avatar>
                        </div>
                        <div @click="contactDetails(obj.contact)" v-if="obj.contact" style="cursor:pointer; color:var(--orange); text-decoration:underline">{{obj.contact.firstName && obj.contact.lastName && obj.contact.firstName.trim() && obj.contact.lastName.trim() ? obj.contact.firstName+' '+obj.contact.lastName : obj.contact.email}}</div>
                        <div @click="opportunityDetails(obj.opportunity)" v-if="obj.opportunity" style="cursor:pointer; color:var(--orange); text-decoration:underline">{{obj.opportunity.name}}</div>
                    </div>
                    <div slot="type" slot-scope="obj">{{getType(obj.type)}}</div>
                    <div slot="priority" slot-scope="obj">
                        <a-popover placement="bottom" trigger="click" overlayClassName="popoverStyle">
                            <template slot="content" >
                                <p class="popoverContent" @click="selectPriority('high',obj)" style="cursor:pointer; margin:0">HIGH PRIORITY</p>
                                <p class="popoverContent" @click="selectPriority('medium',obj)" style="cursor:pointer; margin:0">MED PRIORITY</p>
                                <p class="popoverContent" @click="selectPriority('low',obj)" style="cursor:pointer; margin:0">LOW PRIORITY</p>
                            </template>
                            <div v-if="obj.priority" class="priorityButton py-1 px-3 dF aC jSB" :style="obj.priority === 'high'? {'background-color':'var(--danger)'} : obj.priority === 'medium'? {'background-color':'var(--orange)'} : {'background-color':'var(--teal)'}">{{obj.priority.toUpperCase()+ ' '}}PRIORITY<a-icon class="ml-3" type="caret-down" /></div>
                        </a-popover>

                    </div>

                    <div slot="dateCreated" slot-scope="obj" style="color: #494A4E;">
                        {{numtoDate(obj.dueDate) == 'No Due Date' ? numtoDate(obj.dueDate) : `${numtoDate(obj.dueDate)}`}}
                    </div>

                    <div slot="action" slot-scope="obj" class="dF" style="justify-content: flex-end;">
                        <a-popover trigger="click" placement="bottomRight" overlayClassName="popoverStyle">
                            <div slot="content">
                                <div @click="viewTask(obj)" class="popoverContent"><a-icon type="eye" class="mr-3" /><div>Quick View</div></div>
                                <div @click="editTask(obj)" class="popoverContent"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-3"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>Edit Task</div>
                                <div @click="copyTask(obj)" class="popoverContent"><i class="fe fe-copy mr-3" />Copy Task</div>
                                <a-date-picker @change="onChange" v-model="dueDate">
                                    <span>
                                        <div @click="changeDate(obj)" class="popoverContent"><i class="fe fe-clock mr-3" />Change Due Date</div>
                                    </span>
                                </a-date-picker>
                                <div @click="deleteTask(obj)" class="popoverContent"><i style="color:#FD647C" class="fe fe-trash-2 mr-3" />Delete Task</div>

                            </div>
                            <div class="more-option-icon mr-4">
                                <a-icon style="line-height: 40px;" type="more" />
                            </div>
                        </a-popover>
                    </div>

                    <div slot="comment" slot-scope="obj" class="dF jC" style="color: #9EA0A5;">
                        <div class="mr-4 dF" style="align-items: center;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12.048 12.049" fill="#9ea0a5"> <path id="Icon_awesome-comment-alt" data-name="Icon awesome-comment-alt" class="cls-1" d="M10.542,0H1.506A1.507,1.507,0,0,0,0,1.506V8.283A1.507,1.507,0,0,0,1.506,9.789H3.765v1.977a.283.283,0,0,0,.449.228l2.939-2.2h3.388a1.507,1.507,0,0,0,1.506-1.506V1.506A1.507,1.507,0,0,0,10.542,0Z"/> </svg>
                            <div class="ml-2">
                                <div v-for="(task,taskI) in tasks" :key="task+taskI">
                                    <div v-if="task.id === obj.id">{{tasks[taskI].comments? tasks[taskI].comments.length:0}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="dF" style="align-items: center;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 13.563 15.5" fill="#9ea0a5"> <path id="Icon_awesome-paperclip" data-name="Icon awesome-paperclip" class="cls-1" d="M1.309,14.112a4.743,4.743,0,0,1,.042-6.587l6.35-6.5a3.388,3.388,0,0,1,4.864,0,3.524,3.524,0,0,1,0,4.913L7.03,11.6a2.259,2.259,0,0,1-3.269-.03A2.346,2.346,0,0,1,3.8,8.331L8.156,3.885a.484.484,0,0,1,.685-.007l.692.677a.484.484,0,0,1,.007.685L5.19,9.685a.409.409,0,0,0-.02.554.323.323,0,0,0,.475,0L11.18,4.587a1.586,1.586,0,0,0,0-2.2,1.452,1.452,0,0,0-2.094,0l-6.35,6.5A2.8,2.8,0,0,0,2.7,12.764a2.582,2.582,0,0,0,3.725.009l5.209-5.328a.484.484,0,0,1,.685-.008l.693.677a.484.484,0,0,1,.008.685L7.811,14.126a4.517,4.517,0,0,1-6.5-.015Z"/> </svg>
                            <div class="ml-2">
                                <div v-for="(task,taskI) in tasks" :key="task+taskI">
                                    <div v-if="task.id === obj.id">{{tasks[taskI].files? tasks[taskI].files.length:0}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div slot="assignTo" slot-scope="obj" class="dF">
                        <template v-for="(user,userI) in obj.assignTo">
                            <div v-if="userI < 3" :key="userI" class="owner-icon" :style="userI !== 0? 'margin-left: -5px' : ''">
                                <a-avatar v-if="user.avatar" :src="user.avatar" shape="circle" :size="30"/>
                                <a-avatar v-else>{{user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()}}</a-avatar>
                            </div>
                        </template>
                    </div>
                </a-table>
            </div>

            <div v-else-if="overdueTasks.length == 0 && todayTasks.length == 0 && tomorrowTasks.length == 0 && futureTasks.length == 0 && nodueTasks.length == 0" style="background-color:white; overflow-x:scroll" class="hide-scrollbar">
                <div style="padding:16px 24px; font-size:17px; border-bottom:1px solid #EBEFF2">Tasks</div>
                <a-table :rowKey="(e) => e.id" class="white-table" :scroll="{ x: 1250 }" :columns="columns" :dataSource="[]" :pagination="false">
                </a-table>
            </div>
        </div>


        <!-- Sort By Priority -->
        <div v-else-if="sort == 'priority'">

            <!-- High Priority -->
            <a-table :rowKey="(e) => e.id" v-if="highPriority.length != 0" :scroll="{ x: 1250 }"  class="white-table mb-5" :pagination="false" :columns="columns" :dataSource="highPriority">

                <div slot="name" slot-scope="obj" class="dF" style="color: #323C47;">
                    <div class="mr-3">
                        <a-checkbox class="checkbox-circle" :checked="obj.completed" :value="obj" @change="selectTask"></a-checkbox>
                    </div>
                    <div style="flex: 1;">
                        <div @click="editTask(obj)" style="cursor: pointer;">{{obj.title}}</div>
                    </div>
                </div>

                <div slot="contact" slot-scope="obj" class="dF" style="align-items:center">
                    <div class="mr-3">
                        <a-avatar v-if="obj.contact" style="color:white; background-color: #1070CA">
                            {{obj.contact.firstName && obj.contact.lastName && obj.contact.firstName.trim() && obj.contact.lastName.trim() ? obj.contact.firstName[0].toUpperCase() + obj.contact.lastName[0].toUpperCase() : obj.contact.email[0].toUpperCase()}}
                        </a-avatar>
                        <a-avatar v-if="obj.opportunity" style="color:white; background-color: var(--green)">
                            {{obj.opportunity.name[0].toUpperCase()}}
                        </a-avatar>
                    </div>
                    <div @click="contactDetails(obj.contact)" v-if="obj.contact" style="cursor:pointer; color:var(--orange); text-decoration:underline">{{obj.contact.firstName && obj.contact.lastName && obj.contact.firstName.trim() && obj.contact.lastName.trim() ? obj.contact.firstName+' '+obj.contact.lastName : obj.contact.email}}</div>
                    <div @click="opportunityDetails(obj.opportunity)" v-if="obj.opportunity" style="cursor:pointer; color:var(--orange); text-decoration:underline">{{obj.opportunity.name}}</div>
                </div>
                <div slot="type" slot-scope="obj">{{getType(obj.type)}}</div>
                <div slot="priority" slot-scope="obj">
                    <a-popover placement="bottom" trigger="click" overlayClassName="popoverStyle">
                            <template slot="content" >
                                <p class="popoverContent" @click="selectPriority('high',obj)" style="cursor:pointer; margin:0">HIGH PRIORITY</p>
                                <p class="popoverContent" @click="selectPriority('medium',obj)" style="cursor:pointer; margin:0">MED PRIORITY</p>
                                <p class="popoverContent" @click="selectPriority('low',obj)" style="cursor:pointer; margin:0">LOW PRIORITY</p>
                            </template>
                            <div v-if="obj.priority" class="priorityButton py-1 px-3 dF aC jSB" :style="obj.priority === 'high'? {'background-color':'var(--danger)'} : obj.priority === 'medium'? {'background-color':'var(--orange)'} : {'background-color':'var(--teal)'}">{{obj.priority.toUpperCase()+ ' '}}PRIORITY<a-icon class="ml-3" type="caret-down" /></div>
                        </a-popover>

                </div>

                <div slot="dateCreated" slot-scope="obj" style="color: #494A4E;">
                    {{numtoDate(obj.dueDate) == 'No Due Date' ? numtoDate(obj.dueDate) : `${numtoDate(obj.dueDate)}`}}
                </div>

                <div slot="action" slot-scope="obj" class="dF" style="justify-content: flex-end;">
                    <a-popover trigger="click" placement="bottomRight" overlayClassName="popoverStyle">
                        <div slot="content">
                            <div @click="viewTask(obj)" class="popoverContent"><a-icon type="eye" class="mr-3" /><div>Quick View</div></div>
                            <div @click="editTask(obj)" class="popoverContent"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-3"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>Edit Task</div>
                            <div @click="copyTask(obj)" class="popoverContent"><i class="fe fe-copy mr-3" />Copy Task</div>
                            <a-date-picker @change="onChange" v-model="dueDate">
                                <span>
                                    <div @click="changeDate(obj)" class="popoverContent"><i class="fe fe-clock mr-3" />Change Due Date</div>
                                </span>
                            </a-date-picker>
                            <div @click="deleteTask(obj)" class="popoverContent"><i style="color:#FD647C" class="fe fe-trash-2 mr-3" />Delete Task</div>

                        </div>
                        <div class="more-option-icon mr-4">
                            <a-icon style="line-height: 40px;" type="more" />
                        </div>
                    </a-popover>
                </div>

                <div slot="comment" slot-scope="obj" class="dF jC" style="color: #9EA0A5;">
                    <div class="mr-4 dF" style="align-items: center;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12.048 12.049" fill="#9ea0a5"> <path id="Icon_awesome-comment-alt" data-name="Icon awesome-comment-alt" class="cls-1" d="M10.542,0H1.506A1.507,1.507,0,0,0,0,1.506V8.283A1.507,1.507,0,0,0,1.506,9.789H3.765v1.977a.283.283,0,0,0,.449.228l2.939-2.2h3.388a1.507,1.507,0,0,0,1.506-1.506V1.506A1.507,1.507,0,0,0,10.542,0Z"/> </svg>
                        <div class="ml-2">
                            <div v-for="(task,taskI) in tasks" :key="task+taskI">
                                <div v-if="task.id === obj.id">{{tasks[taskI].comments? tasks[taskI].comments.length:0}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="dF" style="align-items: center;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 13.563 15.5" fill="#9ea0a5"> <path id="Icon_awesome-paperclip" data-name="Icon awesome-paperclip" class="cls-1" d="M1.309,14.112a4.743,4.743,0,0,1,.042-6.587l6.35-6.5a3.388,3.388,0,0,1,4.864,0,3.524,3.524,0,0,1,0,4.913L7.03,11.6a2.259,2.259,0,0,1-3.269-.03A2.346,2.346,0,0,1,3.8,8.331L8.156,3.885a.484.484,0,0,1,.685-.007l.692.677a.484.484,0,0,1,.007.685L5.19,9.685a.409.409,0,0,0-.02.554.323.323,0,0,0,.475,0L11.18,4.587a1.586,1.586,0,0,0,0-2.2,1.452,1.452,0,0,0-2.094,0l-6.35,6.5A2.8,2.8,0,0,0,2.7,12.764a2.582,2.582,0,0,0,3.725.009l5.209-5.328a.484.484,0,0,1,.685-.008l.693.677a.484.484,0,0,1,.008.685L7.811,14.126a4.517,4.517,0,0,1-6.5-.015Z"/> </svg>
                        <div class="ml-2">
                            <div v-for="(task,taskI) in tasks" :key="task+taskI">
                                <div v-if="task.id === obj.id">{{tasks[taskI].files? tasks[taskI].files.length:0}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div slot="assignTo" slot-scope="obj" class="dF">
                    <template v-for="(user,userI) in obj.assignTo">
                        <div v-if="userI < 3" :key="userI" class="owner-icon" :style="userI !== 0? 'margin-left: -5px' : ''">
                            <a-avatar v-if="user.avatar" :src="user.avatar" shape="circle" :size="30"/>
                            <a-avatar v-else>{{user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()}}</a-avatar>
                        </div>
                    </template>
                </div>
            </a-table>

            <!-- Medium Priority -->
            <a-table :rowKey="(e) => e.id" v-if="medPriority.length != 0" :scroll="{ x: 1250 }"  class="white-table mb-5" :pagination="false" :columns="columns" :dataSource="medPriority">

                <div slot="name" slot-scope="obj" class="dF" style="color: #323C47;">
                    <div class="mr-3">
                        <a-checkbox class="checkbox-circle" :checked="obj.completed" :value="obj" @change="selectTask"></a-checkbox>
                    </div>
                    <div style="flex: 1;">
                        <div @click="editTask(obj)" style="cursor: pointer;">{{obj.title}}</div>
                    </div>
                </div>

                <div slot="contact" slot-scope="obj" class="dF" style="align-items:center">
                    <div class="mr-3">
                        <a-avatar v-if="obj.contact" style="color:white; background-color: #1070CA">
                            {{obj.contact.firstName && obj.contact.lastName && obj.contact.firstName.trim() && obj.contact.lastName.trim() ? obj.contact.firstName[0].toUpperCase() + obj.contact.lastName[0].toUpperCase() : obj.contact.email[0].toUpperCase()}}
                        </a-avatar>
                        <a-avatar v-if="obj.opportunity" style="color:white; background-color: var(--green)">
                            {{obj.opportunity.name[0].toUpperCase()}}
                        </a-avatar>
                    </div>
                    <div @click="contactDetails(obj.contact)" v-if="obj.contact" style="cursor:pointer; color:var(--orange); text-decoration:underline">{{obj.contact.firstName && obj.contact.lastName && obj.contact.firstName.trim() && obj.contact.lastName.trim() ? obj.contact.firstName+' '+obj.contact.lastName : obj.contact.email}}</div>
                    <div @click="opportunityDetails(obj.opportunity)" v-if="obj.opportunity" style="cursor:pointer; color:var(--orange); text-decoration:underline">{{obj.opportunity.name}}</div>
                </div>
                <div slot="type" slot-scope="obj">{{getType(obj.type)}}</div>
                <div slot="priority" slot-scope="obj">
                    <a-popover placement="bottom" trigger="click" overlayClassName="popoverStyle">
                            <template slot="content" >
                                <p class="popoverContent" @click="selectPriority('high',obj)" style="cursor:pointer; margin:0">HIGH PRIORITY</p>
                                <p class="popoverContent" @click="selectPriority('medium',obj)" style="cursor:pointer; margin:0">MED PRIORITY</p>
                                <p class="popoverContent" @click="selectPriority('low',obj)" style="cursor:pointer; margin:0">LOW PRIORITY</p>
                            </template>
                            <div v-if="obj.priority" class="priorityButton py-1 px-3 dF aC jSB" :style="obj.priority === 'high'? {'background-color':'var(--danger)'} : obj.priority === 'medium'? {'background-color':'var(--orange)'} : {'background-color':'var(--teal)'}">{{obj.priority.toUpperCase()+ ' '}}PRIORITY<a-icon class="ml-3" type="caret-down" /></div>
                        </a-popover>
                </div>

                <div slot="dateCreated" slot-scope="obj" style="color: #494A4E;">
                    {{numtoDate(obj.dueDate) == 'No Due Date' ? numtoDate(obj.dueDate) : `${numtoDate(obj.dueDate)}`}}
                </div>

                <div slot="action" slot-scope="obj" class="dF" style="justify-content: flex-end;">
                    <a-popover trigger="click" placement="bottomRight" overlayClassName="popoverStyle">
                        <div slot="content">
                            <div @click="viewTask(obj)" class="popoverContent"><a-icon type="eye" class="mr-3" /><div>Quick View</div></div>
                            <div @click="editTask(obj)" class="popoverContent"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-3"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>Edit Task</div>
                            <div @click="copyTask(obj)" class="popoverContent"><i class="fe fe-copy mr-3" />Copy Task</div>
                            <a-date-picker @change="onChange" v-model="dueDate">
                                <span>
                                    <div @click="changeDate(obj)" class="popoverContent"><i class="fe fe-clock mr-3" />Change Due Date</div>
                                </span>
                            </a-date-picker>
                            <div @click="deleteTask(obj)" class="popoverContent"><i style="color:#FD647C" class="fe fe-trash-2 mr-3" />Delete Task</div>

                        </div>
                        <div class="more-option-icon mr-4">
                            <a-icon style="line-height: 40px;" type="more" />
                        </div>
                    </a-popover>
                </div>

                <div slot="comment" slot-scope="obj" class="dF jC" style="color: #9EA0A5;">
                    <div class="mr-4 dF" style="align-items: center;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12.048 12.049" fill="#9ea0a5"> <path id="Icon_awesome-comment-alt" data-name="Icon awesome-comment-alt" class="cls-1" d="M10.542,0H1.506A1.507,1.507,0,0,0,0,1.506V8.283A1.507,1.507,0,0,0,1.506,9.789H3.765v1.977a.283.283,0,0,0,.449.228l2.939-2.2h3.388a1.507,1.507,0,0,0,1.506-1.506V1.506A1.507,1.507,0,0,0,10.542,0Z"/> </svg>
                        <div class="ml-2">
                            <div v-for="(task,taskI) in tasks" :key="task+taskI">
                                <div v-if="task.id === obj.id">{{tasks[taskI].comments? tasks[taskI].comments.length:0}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="dF" style="align-items: center;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 13.563 15.5" fill="#9ea0a5"> <path id="Icon_awesome-paperclip" data-name="Icon awesome-paperclip" class="cls-1" d="M1.309,14.112a4.743,4.743,0,0,1,.042-6.587l6.35-6.5a3.388,3.388,0,0,1,4.864,0,3.524,3.524,0,0,1,0,4.913L7.03,11.6a2.259,2.259,0,0,1-3.269-.03A2.346,2.346,0,0,1,3.8,8.331L8.156,3.885a.484.484,0,0,1,.685-.007l.692.677a.484.484,0,0,1,.007.685L5.19,9.685a.409.409,0,0,0-.02.554.323.323,0,0,0,.475,0L11.18,4.587a1.586,1.586,0,0,0,0-2.2,1.452,1.452,0,0,0-2.094,0l-6.35,6.5A2.8,2.8,0,0,0,2.7,12.764a2.582,2.582,0,0,0,3.725.009l5.209-5.328a.484.484,0,0,1,.685-.008l.693.677a.484.484,0,0,1,.008.685L7.811,14.126a4.517,4.517,0,0,1-6.5-.015Z"/> </svg>
                        <div class="ml-2">
                            <div v-for="(task,taskI) in tasks" :key="task+taskI">
                                <div v-if="task.id === obj.id">{{tasks[taskI].files? tasks[taskI].files.length:0}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div slot="assignTo" slot-scope="obj" class="dF">
                    <template v-for="(user,userI) in obj.assignTo">
                        <div v-if="userI < 3" :key="userI" class="owner-icon" :style="userI !== 0? 'margin-left: -5px' : ''">
                            <a-avatar v-if="user.avatar" :src="user.avatar" shape="circle" :size="30"/>
                            <a-avatar v-else>{{user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()}}</a-avatar>
                        </div>
                    </template>
                </div>
            </a-table>

            <!-- Low Priority -->
            <a-table :rowKey="(e) => e.id" v-if="lowPriority.length != 0" :scroll="{ x: 1250 }"  class="white-table mb-5" :pagination="false" :columns="columns" :dataSource="lowPriority">

                <div slot="name" slot-scope="obj" class="dF" style="color: #323C47;">
                    <div class="mr-3">
                        <a-checkbox class="checkbox-circle" :checked="obj.completed" :value="obj" @change="selectTask"></a-checkbox>
                    </div>
                    <div style="flex: 1;">
                        <div @click="editTask(obj)" style="cursor: pointer;">{{obj.title}}</div>
                    </div>
                </div>

                <div slot="contact" slot-scope="obj" class="dF" style="align-items:center">
                    <div class="mr-3">
                        <a-avatar v-if="obj.contact" style="color:white; background-color: #1070CA">
                            {{obj.contact.firstName && obj.contact.lastName && obj.contact.firstName.trim() && obj.contact.lastName.trim() ? obj.contact.firstName[0].toUpperCase() + obj.contact.lastName[0].toUpperCase() : obj.contact.email[0].toUpperCase()}}
                        </a-avatar>
                        <a-avatar v-if="obj.opportunity" style="color:white; background-color: var(--green)">
                            {{obj.opportunity.name[0].toUpperCase()}}
                        </a-avatar>
                    </div>
                    <div @click="contactDetails(obj.contact)" v-if="obj.contact" style="cursor:pointer; color:var(--orange); text-decoration:underline">{{obj.contact.firstName && obj.contact.lastName && obj.contact.firstName.trim() && obj.contact.lastName.trim() ? obj.contact.firstName+' '+obj.contact.lastName : obj.contact.email}}</div>
                    <div @click="opportunityDetails(obj.opportunity)" v-if="obj.opportunity" style="cursor:pointer; color:var(--orange); text-decoration:underline">{{obj.opportunity.name}}</div>
                </div>
                <div slot="type" slot-scope="obj">{{getType(obj.type)}}</div>
                <div slot="priority" slot-scope="obj">
                    <a-popover placement="bottom" trigger="click" overlayClassName="popoverStyle">
                            <template slot="content" >
                                <p class="popoverContent" @click="selectPriority('high',obj)" style="cursor:pointer; margin:0">HIGH PRIORITY</p>
                                <p class="popoverContent" @click="selectPriority('medium',obj)" style="cursor:pointer; margin:0">MED PRIORITY</p>
                                <p class="popoverContent" @click="selectPriority('low',obj)" style="cursor:pointer; margin:0">LOW PRIORITY</p>
                            </template>
                            <div v-if="obj.priority" class="priorityButton py-1 px-3 dF aC jSB" :style="obj.priority === 'high'? {'background-color':'var(--danger)'} : obj.priority === 'medium'? {'background-color':'var(--orange)'} : {'background-color':'var(--teal)'}">{{obj.priority.toUpperCase()+ ' '}}PRIORITY<a-icon class="ml-3" type="caret-down" /></div>
                        </a-popover>

                </div>

                <div slot="dateCreated" slot-scope="obj" style="color: #494A4E;">
                    {{numtoDate(obj.dueDate) == 'No Due Date' ? numtoDate(obj.dueDate) : `${numtoDate(obj.dueDate)}`}}
                </div>

                <div slot="action" slot-scope="obj" class="dF" style="justify-content: flex-end;">
                    <a-popover trigger="click" placement="bottomRight" overlayClassName="popoverStyle">
                        <div slot="content">
                            <div @click="viewTask(obj)" class="popoverContent"><a-icon type="eye" class="mr-3" /><div>Quick View</div></div>
                            <div @click="editTask(obj)" class="popoverContent"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-3"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>Edit Task</div>
                            <div @click="copyTask(obj)" class="popoverContent"><i class="fe fe-copy mr-3" />Copy Task</div>
                            <a-date-picker @change="onChange" v-model="dueDate">
                                <span>
                                    <div @click="changeDate(obj)" class="popoverContent"><i class="fe fe-clock mr-3" />Change Due Date</div>
                                </span>
                            </a-date-picker>
                            <div @click="deleteTask(obj)" class="popoverContent"><i style="color:#FD647C" class="fe fe-trash-2 mr-3" />Delete Task</div>

                        </div>
                        <div class="more-option-icon mr-4">
                            <a-icon style="line-height: 40px;" type="more" />
                        </div>
                    </a-popover>
                </div>

                <div slot="comment" slot-scope="obj" class="dF jC" style="color: #9EA0A5;">
                    <div class="mr-4 dF" style="align-items: center;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12.048 12.049" fill="#9ea0a5"> <path id="Icon_awesome-comment-alt" data-name="Icon awesome-comment-alt" class="cls-1" d="M10.542,0H1.506A1.507,1.507,0,0,0,0,1.506V8.283A1.507,1.507,0,0,0,1.506,9.789H3.765v1.977a.283.283,0,0,0,.449.228l2.939-2.2h3.388a1.507,1.507,0,0,0,1.506-1.506V1.506A1.507,1.507,0,0,0,10.542,0Z"/> </svg>
                        <div class="ml-2">
                            <div v-for="(task,taskI) in tasks" :key="task+taskI">
                                <div v-if="task.id === obj.id">{{tasks[taskI].comments? tasks[taskI].comments.length:0}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="dF" style="align-items: center;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 13.563 15.5" fill="#9ea0a5"> <path id="Icon_awesome-paperclip" data-name="Icon awesome-paperclip" class="cls-1" d="M1.309,14.112a4.743,4.743,0,0,1,.042-6.587l6.35-6.5a3.388,3.388,0,0,1,4.864,0,3.524,3.524,0,0,1,0,4.913L7.03,11.6a2.259,2.259,0,0,1-3.269-.03A2.346,2.346,0,0,1,3.8,8.331L8.156,3.885a.484.484,0,0,1,.685-.007l.692.677a.484.484,0,0,1,.007.685L5.19,9.685a.409.409,0,0,0-.02.554.323.323,0,0,0,.475,0L11.18,4.587a1.586,1.586,0,0,0,0-2.2,1.452,1.452,0,0,0-2.094,0l-6.35,6.5A2.8,2.8,0,0,0,2.7,12.764a2.582,2.582,0,0,0,3.725.009l5.209-5.328a.484.484,0,0,1,.685-.008l.693.677a.484.484,0,0,1,.008.685L7.811,14.126a4.517,4.517,0,0,1-6.5-.015Z"/> </svg>
                        <div class="ml-2">
                            <div v-for="(task,taskI) in tasks" :key="task+taskI">
                                <div v-if="task.id === obj.id">{{tasks[taskI].files? tasks[taskI].files.length:0}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div slot="assignTo" slot-scope="obj" class="dF">
                    <template v-for="(user,userI) in obj.assignTo">
                        <div v-if="userI < 3" :key="userI" class="owner-icon" :style="userI !== 0? 'margin-left: -5px' : ''">
                            <a-avatar v-if="user.avatar" :src="user.avatar" shape="circle" :size="30"/>
                            <a-avatar v-else>{{user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()}}</a-avatar>
                        </div>
                    </template>
                </div>
            </a-table>

            <div v-else-if="highPriority.length == 0 && medPriority.length == 0 && lowPriority.length == 0" style="background-color:white; overflow-x:scroll" class="hide-scrollbar">
                <div style="padding:16px 24px; font-size:17px; border-bottom:1px solid #EBEFF2">Tasks</div>
                <a-table :rowKey="(e) => e.id" class="white-table" :scroll="{ x: 1250 }" :columns="columns" :dataSource="[]" :pagination="false">
                </a-table>
            </div>
        </div>
    </div>
</template>

<script>
const day = Date.now()
const today = new Date();
const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
import EditTaskModal from '@/components/contacts/EditTaskModal'
import moment from 'moment'
import Index from '../layout/SubBar/index.vue';
import BHLoading from 'bh-mod/components/common/Loading'


export default {
    components:{
        EditTaskModal,BHLoading,
    },
    props:{
        // data:{
        //     default:[],
        //     type:Array
        // }
    },
    computed:{
		dateFormat(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
        taskTypes() {
            if (this.$store.state.contacts?.allSettings?.app?.options?.taskType?.length) {
                return this.$store.state.contacts.allSettings.app.options.taskType
            } else {
                return [
                    {name:'To-do', value:'todo'},
                    {name:'Call', value:'call'},
                    {name:'Follow up', value:'followup'}
                ]
            }
        },
        externalContacts(){
            return this.$store.state.contacts.externalContacts
        },
        opportunities(){
            return this.$store.state.contacts.opportunities
        },
        sidebarSelection(){
            return this.$store.state.sidebarSelection
        },
        instance(){
            return this.$store.state.instance
        },
        contacts(){
            console.log('CONTACTSSSSS', Object.values(this.$store.state.contacts.allContacts))
            return Object.values(this.$store.state.contacts.allContacts)
        },
        tasks(){
            let test = this.test
            return this.$store.state.contacts.tasks
        },
        lowPriority (){
            let list = []
            let tasks = this.$store.state.contacts.tasks
            let test = this.test
            tasks.forEach(task => {
                if (task.priority == 'low' && !task.completed) {
                    list.push(task)
                }
            })
            list = list.sort((a,b) => {
                if (a.dueDate > b.dueDate) return 1
                else if (a.dueDate < b.dueDate) return -1
                else return 0
            })
            return list
        },
        medPriority (){
            let list = []
            let tasks = this.$store.state.contacts.tasks
            let test = this.test
            tasks.forEach(task => {
                if (task.priority == 'medium' && !task.completed) {
                    list.push(task)
                }
            })
            list = list.sort((a,b) => {
                if (a.dueDate > b.dueDate) return 1
                else if (a.dueDate < b.dueDate) return -1
                else return 0
            })
            return list
        },
        highPriority (){
            let list = []
            let tasks = this.$store.state.contacts.tasks
            let test = this.test
            tasks.forEach(task => {
                if (task.priority == 'high' && !task.completed) {
                    list.push(task)
                }
            })
            list = list.sort((a,b) => {
                if (a.dueDate > b.dueDate) return 1
                else if (a.dueDate < b.dueDate) return -1
                else return 0
            })
            return list
        },
        overdueTasks(){
            let list = []
            let tasks = this.$store.state.contacts.tasks
            let test = this.test
            tasks.forEach(task => {
                if (task.dueDate != 0  && !task.completed) {
                    let a = this.moment(task.dueDate).format()
                    let year = parseInt(a.substring(0,4))
                    let month = parseInt(a.substring(5,7))
                    let day = parseInt(a.substring(8,10))
                    let tYear = today.getFullYear()
                    let tMonth = today.getMonth()+1
                    let tDay = today.getDate()
                    if (year < tYear) {
                        list.push(task)
                    } else if (year == tYear) {
                        if (month < tMonth) {
                            list.push(task)
                        } else if (month == tMonth) {
                            if (day < tDay) {
                                list.push(task)
                            }
                        }
                    }
                }
            })
            list = list.sort((a,b) => {
                if (a.dueDate > b.dueDate) return 1
                else if (a.dueDate < b.dueDate) return -1
                else return 0
            })
            return list
        },
        todayTasks(){
            let list = []
            let tasks = this.$store.state.contacts.tasks
            let test = this.test
            tasks.forEach(task => {
                let a = this.moment(task.dueDate).format()
                let year = parseInt(a.substring(0,4))
                let month = parseInt(a.substring(5,7))
                let day = parseInt(a.substring(8,10))
                let tYear = today.getFullYear()
                let tMonth = today.getMonth()+1
                let tDay = today.getDate()
                if (year == tYear && month == tMonth && day == tDay && !task.completed) {
                    list.push(task)
                }
            })
            list = list.sort((a,b) => {
                if (a.dueDate > b.dueDate) return 1
                else if (a.dueDate < b.dueDate) return -1
                else return 0
            })
            return list
        },
        tomorrowTasks(){
            let list = []
            let tasks = this.$store.state.contacts.tasks
            let test = this.test
            tasks.forEach(task => {
                let a = this.moment(task.dueDate).format()
                let year = parseInt(a.substring(0,4))
                let month = parseInt(a.substring(5,7))
                let day = parseInt(a.substring(8,10))
                let tYear = today.getFullYear()
                let tMonth = today.getMonth()+1
                let tDay = today.getDate()
                if (year == tYear && month == tMonth && day == tDay+1 && !task.completed) {
                    list.push(task)
                }
            })
            list = list.sort((a,b) => {
                if (a.dueDate > b.dueDate) return 1
                else if (a.dueDate < b.dueDate) return -1
                else return 0
            })
            return list
        },
        futureTasks(){
            let list = []
            let tasks = this.$store.state.contacts.tasks
            let test = this.test
            let temp = []
            temp = this.todayTasks.concat(this.tomorrowTasks, this.overdueTasks, this.nodueTasks)
            tasks.forEach(task => {
                let index = temp.findIndex(t => t.id == task.id)
                if (index == -1 && !task.completed) {
                    list.push(task)
                }
            })
            list = list.sort((a,b) => {
                if (a.dueDate > b.dueDate) return 1
                else if (a.dueDate < b.dueDate) return -1
                else return 0
            })
            console.log('list', list)
            return list
        },
        nodueTasks(){
            let list = []
            let tasks = this.$store.state.contacts.tasks
            let test = this.test
            tasks.forEach(task => {
                if ((task.dueDate == '' || task.dueDate == null || task.dueDate == 0) && !task.completed) {
                    list.push(task)
                }
            })
            list = list.sort((a,b) => {
                if (a.dueDate > b.dueDate) return 1
                else if (a.dueDate < b.dueDate) return -1
                else return 0
            })
            return list
        }
    },
    watch:{

    },
    data(){
        return{
            loading:false,
			resizeObserver: null,
            sortType:[
                {label:'Due Date', value:'dueDate'},
                {label:'Priority Level', value:'priority'}
            ],
            sort:'dueDate',
            test:0,
            sortedData:[],
            dueDate:'',
            newTask:{
                obj:{},
                dueDate:''
            },
            columns: [
				{
					title: 'Title',
					key: 'name',
					scopedSlots: { customRender: 'name' },
					sorter: (a, b) => (a.title || '').localeCompare((b.title || '')),
					width: 300,
				},
				{
					title: 'Task Type',
					key: 'type',
					scopedSlots: { customRender: 'type' },
					sorter: (a, b) => (a.type || '').localeCompare((b.type || '')),
				},
				{
					title: 'Description',
					key: 'description',
					scopedSlots: { customRender: 'description' },
					sorter: (a, b) => (a.description || '').localeCompare((b.description || '')),
					width: 200,
				},
				{
					title: 'Primary Contact',
					key: 'contact',
					scopedSlots: { customRender: 'contact' },
				},
				{
					title: 'Priority Level',
					key: 'priority',
					scopedSlots: { customRender: 'priority' },
					sorter: (a, b) => (a.priority || '').localeCompare((b.priority || '')),
					width: 200
				},
				{
					title: 'Due Date',
					key: 'dateCreated',
					scopedSlots: { customRender: 'dateCreated' },
					sorter: (a, b) => (a.dueDate || 0) - (b.dueDate || 0),
				},
				{
					title: 'Assign To',
					key: 'assignTo',
					scopedSlots: { customRender: 'assignTo' },
				},
				{
					title: '',
					key: 'comment',
					scopedSlots: { customRender: 'comment' },
				},
				{
					title: '',
					key: 'action',
					scopedSlots: { customRender: 'action' },
					width: 70
				},
			],
        }
    },
    methods:{
        viewTask(task) {
            this.$store.commit('OPEN_PREVIEW_MODAL', {type:'task', object:task})
        },
        getType(typeId) {
            let type = this.taskTypes.find(type => {
                if (type.id == typeId) return type
                else if (type.value == typeId) return type
            })


            if (type) {
                return type.name
            } else return 'N/A'
        },
        selectPriority(item,obj) {
            console.log('priority', item,obj)
            let task = JSON.parse(JSON.stringify(obj))
            if (typeof(task.dueDate) == 'string') {
                task.dueDate = parseInt(this.moment(task.dueDate).format('X'))*1000
            }
            task.priority = item

            this.$api.put(`/tasks/${this.instance.id}/${task.id}`, task). then(({data}) => {
                console.log('just updated', data)
                this.$store.commit('UPDATE_TASK', data)
                this.test = Date.now()
                this.$emit('updatetable')
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        onChange(value, dateString){
            console.log('VALUEEEE', value)
            console.log('DATESTRINGGGG', dateString)
            this.newTask.dueDate = parseInt(this.moment(dateString).format('X'))*1000

            this.newTask.obj.dueDate = this.newTask.dueDate
            console.log('NEW TASK DUE DATE', this.newTask, this.newTask.dueDate)
            this.loading = true
            this.$api.put(`/tasks/${this.instance.id}/${this.newTask.obj.id}`, this.newTask.obj).then( ({data}) => {
                console.log('update task', data)
                this.test = Date.now()
                this.$store.commit('UPDATE_TASK', data)
                this.loading = false
                this.$notification['success']({
                    message: 'Due Date Changed',
                    description: 'Your task has been updated successfully.',
                    duration: 4
                });
            }).catch(err => {
				this.loading = false
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        updateTable(){
            console.log('JUPDATE TABLE RAN')
            this.test = Date.now()
        },
        moment,
        contactDetails(obj){
            console.log('CONTACT CLICKED', obj)
            let contacts = this.$store.state.contacts.allContacts

            if (contacts.hasOwnProperty(`${obj.id}`)){
                this.$emit('contactdetails', contacts[obj.id])
            } else if (this.externalContacts.hasOwnProperty(`${obj.id}`)){
                this.$emit('contactdetails', this.externalContacts[obj.id])
            } else {
                this.$api.get(`/contacts/:instance/${obj.id}`).then( ({data}) => {
                    console.log('DATA COMING BACK FROM GET REQUEST', data)
                    this.$store.commit('ADD_EXTERNAL_CONTACT', data)
                    this.$emit('contactdetails', data)
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            }
        },
        opportunityDetails(newObj){
            console.log('OPPORTUNITY CLICKED', newObj)
            let contacts = this.$store.state.contacts.allContacts
            let index = this.opportunities.findIndex( x => x.id == newObj.id)
            let obj = this.opportunities[index]
            if (typeof(obj.contact) == 'string'){
                if (contacts.hasOwnProperty(`${obj.id}`)){
                    obj.contact = contacts[obj.id]
                    this.$store.commit('OPEN_OPPORTUNITY_DETAILS', obj)
                } else if (this.externalContacts.hasOwnProperty(`${obj.id}`)){
                    obj.contact = this.externalContacts[obj.id]
                    this.$store.commit('OPEN_OPPORTUNITY_DETAILS', obj)
                } else {
                    this.$api.get(`/contacts/:instance/${obj.contact}`).then( ({data}) => {
                        console.log('DATA COMING BACK FROM GET REQUEST', data)
                        this.$store.commit('ADD_EXTERNAL_CONTACT', data)
                        obj.contact = data
                        this.$store.commit('OPEN_OPPORTUNITY_DETAILS', obj)
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
                }
            } else {
                this.$store.commit('OPEN_OPPORTUNITY_DETAILS', obj)
            }

        },
        selectSort(item){
            console.log('iteeemm', item)
        },
        numtoDate(num) {
            if (num != 0 && num != null) {
                num = this.moment(num).format()
                let year = num.substring(0,4)
                let month = num.substring(5,7)
                let day = num.substring(8,10)
                let months = ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sept','Oct','Nov','Dec']
                month = months[month-1]
                return month + '/' + day + '/' + year
            } else {
                console.log('aaa', num)
                return 'No Due Date'
            }
        },
        convertDate(num){
			let x = new Date(num)
			//Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
			let year = x.getFullYear()
			let month = x.getMonth()+1
			let day = x.getDate()
			if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
			else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
			else {
				return month+'/'+day+'/'+year
			}
		},
        deleteTask(item) {
            if (this.$p < 40 && (!item.createdBy || item.createdBy.id !== this.$store.state.user.user.id)){
                return this.$message.error('You do not have permission to delete Tasks')
            }
            let self = this
            this.$confirm({
                title: "Delete",
                content: h => <div>Do you want to delete this Task?</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    self.$api.delete(`/tasks/${self.$store.state.instance.id}/${item.id}`).then( ({data}) => {
                        console.log('just delete', data)
                        self.$store.commit('DELETE_TASK', data)
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        editTask(item) {
            console.log('EDIT TASK CLICKED', item)
            let editTask = this.tasks.find(x => x.id == item.id)
            this.$store.commit('EDIT_TASK', editTask)
        },
        changeDate(item) {
            this.newTask.obj = JSON.parse(JSON.stringify(item))
            if (item.dueDate != 0) {
                this.dueDate = this.moment(item.dueDate).format()
            } else {
                let time = this.moment(Date.now()).format('X')*1000
                this.dueDate = this.moment(time).format()
            }
        },
        copyTask(item) {
            console.log('aaa copy',item)
            this.$store.commit('ADD_NEW', 'Task')
            this.$store.commit('DUPLICATE', item)
            if (item.hasOwnProperty('contact')){
                console.log('TASK HAS CONTACT PROPERTY')
                setTimeout(() => {
                    this.$store.commit('UPDATE_QUERY_CONTACT',item.contact)
                }, 50)
            } else {
                console.log('TASK HAS OPPORTUNITY PROPERTY')
                setTimeout(() => {
                    this.$store.commit('CHANGE_TYPE', item.opportunity)
                }, 50)
            }

        },
        cancelEdit(){
            this.$store.commit('CLOSE_EDIT_TASK')
        },
        selectTask(item) {
            let task = item.target.value
            console.log('item', item.target.checked)
            task.completed = item.target.checked
            console.log('task', task)
            this.loading = true
            this.$api.put(`/tasks/${this.instance.id}/${task.id}`, task).then( ({data}) => {
                this.$store.commit('UPDATE_TASK', data)
                this.test = Date.now()
                this.loading = false
                this.$notification['success']({
                    message: 'Update Success',
                    description: 'Your task has been moved to Completed.',
                    duration: 4
                });
            }).catch(err => {
				this.loading = false
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },

		updateScrollVisibility(){
			let scrollContent = document.querySelector('div.ant-table-body');
			let scrollLeftButton = document.querySelector('.scroll-left')
			let scrollRightButton = document.querySelector('.scroll-right')

			if(scrollContent && scrollLeftButton && scrollRightButton) {
				if(scrollContent.offsetWidth < scrollContent.scrollWidth){
					scrollLeftButton.classList.remove('hide');
					scrollRightButton.classList.remove('hide');
					scrollContent.classList.add('mx-3');
				} else {
					scrollLeftButton.classList.add('hide');
					scrollRightButton.classList.add('hide');
					scrollContent.classList.remove('mx-3');
				}
			}
		},
    },
    created(){
        console.log('this date', day, today,date)
		if (this.$route.query && this.$route.query.id) {
			const task = this.$store.state.contacts.tasks.find(t => t.id === this.$route.query.id)
			if (task) {
				setTimeout(() => {
					this.viewTask(task)
				}, 0)
			}
		}
    },
	mounted() {
		this.$nextTick(() => {
			const scrollableArea = document.querySelector('div.ant-table-body')

			var scrollLeftButton = document.createElement('div');
			var scrollRightButton = document.createElement('div');

			scrollLeftButton.className = 'scroll-button scroll-left';
			scrollLeftButton.innerHTML = '&lt;';
			scrollableArea.appendChild(scrollLeftButton);

			scrollRightButton.className = 'scroll-button scroll-right';
			scrollRightButton.innerHTML = '&gt;';
			scrollableArea.appendChild(scrollRightButton);


			scrollLeftButton.addEventListener('click', function () {
				const scrollSize = Math.floor(scrollableArea.clientWidth - (scrollableArea.clientWidth * 0.1))
				scrollableArea.scrollBy({
					left: -scrollSize,
					behavior: "smooth",
				})
			});

			scrollRightButton.addEventListener('click', function () {
				const scrollSize = Math.floor(scrollableArea.clientWidth - (scrollableArea.clientWidth * 0.1))
				scrollableArea.scrollBy({
					left: scrollSize,
					behavior: "smooth",
				})
			});
		})

		let scrollableArea = document.querySelector('div.ant-table-body');
		this.resizeObserver = new ResizeObserver(() => {
			window.requestAnimationFrame(() => {
				this.updateScrollVisibility()
			})
		});
		if(scrollableArea){
			this.resizeObserver.observe(scrollableArea);
		}

		// Remove the previous event listener, if any, to prevent multiple bindings
		window.removeEventListener('resize', this.updateScrollVisibility);

		// Attach the updated event listener
		window.addEventListener('resize', this.updateScrollVisibility)
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updateScrollVisibility)
		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
        }
    },
}
</script>
<style>
.checkbox-circle .ant-checkbox-inner{
    border-radius: 50%;
}
.checkbox-circle .ant-checkbox-checked::after {
    border:none;
}
.reformat-description p{
    margin-bottom:0;
    width:168px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow:hidden;
}
</style>
<style scoped lang="scss">
.more-option-icon{
    width: 35px;
    height: 35px;
    border-radius: 35px;
    text-align: center;
    background-color: transparent;
}
.more-option-icon:hover{
    color: var(--orange) !important;
    cursor: pointer;
    background-color: var(--light-gray);
}

.popoverContent{
    height: 35px;
    // width: 100px;
    line-height: 35px;
    padding-left: 10px;
    padding-right:10px;
    display:flex;
    align-items: center;
}
.popoverContent:hover{
    background-color: var(--off-white-light);
    cursor: pointer;
    color:#000;
}
.priorityButton {
    border-radius:4px;
    text-align:center;
    color:white;
    width: 167px;
    cursor: pointer;
}
</style>
<style lang="scss">
.popoverStyle .ant-popover-inner-content {
        padding: 0;
        background-color: white;
        border-radius: 20px;
    }

    .activeTable .ant-table-thead {
        display: none;
    }
</style>
