<template>
    <div>
        <BHLoading :show="loading" />
        <lostWonModal :opportunity="lostWon" @closed="closeLostWon" @won="updateWon" @lost="updateLost" />
        <a-form-model ref="opportunity" :model="opportunity">
            <a-card>
                <div slot="title">
                    <div class="dF" style="line-height: 26.5px; padding-top: 5px font-size: 14px; color: #40454c;">
                        <div class="dF mr-3"><div>Deal Stage</div>&nbsp;<div style="color:var(--danger)">*</div></div>
                        <a-tooltip overlayClassName="change-tooltip-color">
                            <template slot="title">
                                Select the Deal Stage
                            </template>
                            <a-icon type="question-circle" style="line-height: 22.5px; margin-top: 5px; color: var(--orange);" />
                        </a-tooltip>
                    </div>
                    <a-form-model-item prop="stage" required :rules="req('Please select one')">
                        <a-select @change="changeStage" v-model="opportunity.stage" placeholder="Select one" size="large" :disabled="originalOpportunity.stage == 'transaction' || originalOpportunity.stage == 'won' ? true : false">
                            <div slot="dropdownRender" slot-scope="menu">
                                <v-nodes :vnodes="menu" />
                                <a-divider style="margin: 4px 0;" />
                                <div
                                    style="padding: 8px; cursor: pointer; color:var(--orange)"
                                    @mousedown="e => e.preventDefault()"
                                    @click="addNewStage"
                                >
                                    <i style="font-size:15px;" class="fe fe-edit-2 mr-2" /> Add New Deal Stage
                                </div>
                            </div>
                            <template v-for="(stage, stageI) in dealStages">
                                <a-select-option v-if="stage.id != 'transaction'" :key="stage.id+stageI" :value="stage.id">{{stage.name}}</a-select-option>
                            </template>
                            <template v-if="opportunity.stage == 'transaction'">
                                <a-select-option value="transaction">In Transaction</a-select-option>
                            </template>
                        </a-select>
                    </a-form-model-item>
                </div>
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-model-item :label="instance.productType == 'lowrise' ? 'Lot' : 'Unit'" prop="product" required :rules="req('Please select a product')">
                            <!-- <a-select v-if="instance.productType == 'lowrise'" v-model="opportunity.product" size="large" :disabled="opportunity.stage == 'transaction' || opportunity.stage == 'won' || opportunity.stage == 'lost' ? true : false">
                                <template v-for="(prod,prodI) in products">
                                    <a-select-option :disabled="prod.status == 'available' ? false : true" :value="prod.id" :key="prod+prodI">{{prod.type[0].toUpperCase() + prod.type.slice(1) + ' ' + prod.name}}</a-select-option>
                                </template>
                            </a-select> -->
                            <a-cascader v-if="instance.productType == 'lowrise'" :options="products" :default-value="defaultCascader" :disabled="opportunity.stage == 'transaction' ? true : false" size="large" @change="selectLot" placeholder="Select a lot" />
                            <a-cascader v-if="instance.productType == 'highrise'" :options="products" :default-value="defaultCascader" :disabled="opportunity.stage == 'transaction' ? true : false" size="large" @change="selectProduct" placeholder="Select a unit" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="Primary Contact" prop="contact" required :rules="req('Please select a contact')">
                            <searchContact :id="opportunity.contact || ''" @updatecontact="updatePerson" :disabled="opportunity.stage == 'transaction' ? true : false" />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="12">
                        <div class="dF" style="line-height: 26.5px; padding-top: 5px font-size: 14px; color: #40454c;">
                            <div class="dF mr-3"><div>{{ opportunity.stage == 'won' || opportunity.stage == 'lost' ? 'Executed Date' : 'Estimated Opportunity Close Date' }}</div>&nbsp;<div style="color:var(--danger)">*</div></div>
                            <a-tooltip overlayClassName="change-tooltip-color">
                                <template slot="title">
                                    Select the estimated close date of the opportunity, not when the home will close
                                </template>
                                <a-icon type="question-circle" style="line-height: 22.5px; margin-top: 5px; color: var(--orange);" />
                            </a-tooltip>
                        </div>
                        <a-form-model-item prop="closeDate" required :rules="req('Please enter the close date')">
                            <a-date-picker v-model="opportunity.closeDate" :disabled="opportunity.stage == 'transaction' ? true : false" size="large" placeholder="dd/mm/yyyy" style="width: 100%;" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="Deal Source" prop="dealSource">
                            <a-select v-model="opportunity.dealSource" style="width: 100%;" size="large">
                                <div slot="dropdownRender" slot-scope="menu">
                                    <v-nodes :vnodes="menu" />
                                    <a-divider style="margin: 4px 0;" />
                                    <div
                                        style="padding: 8px; cursor: pointer; color:var(--orange)"
                                        @mousedown="e => e.preventDefault()"
                                        @click="addNewSource"
                                    >
                                        <i style="font-size:15px;" class="fe fe-edit-2 mr-2" /> Add New Deal Source
                                    </div>
                                </div>
                                <a-select-option v-for="(source) in dealSources" :key="source.id" :value="source.id">
                                    {{source.name}}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-model-item label="Value" prop="dealValue" required :rules="req('Please enter a value')">
                            <!-- <a-input v-model="opportunity.dealValue" :disabled="opportunity.stage == 'transaction' ? true : false" size="large" placeholder="Add deal value" type="number" /> -->
							<a-input-number size="large" style="width:100%" :disabled="opportunity.stage == 'transaction' ? true : false" v-model="opportunity.dealValue" :formatter="price => `$ ${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0" placeholder="Add deal value" ></a-input-number>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="Visibility" prop="visibility">
                            <a-select @change="changeVisibility" v-model="opportunity.visibility" style="width: 100%;" size="large">
                                <a-select-option value="everyone">
                                    Everyone
                                </a-select-option>
                                <a-select-option value="owners">
                                    Only Sales Owner(s)
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-model-item label="Sales Owner" prop="owners" required :rules="req('Please select an owner')">
                            <a-select
                                v-model="opportunity.owners"
								mode="multiple"
                                placeholder="Add owner"
                                size="large"
                                :filter-option="filterOption"
                                :not-found-content="null"
                                :disabled="opportunity.stage == 'transaction' ? true : false"
                                @search="searchAssign">
                                <a-select-option v-for="user in userSource" :key="user.id" :value="user.id">
                                    {{ user.firstName + ' ' + user.lastName }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="24">
                        <div class="dF" style="line-height: 26.5px; padding-top: 5px font-size: 14px; color: #40454c;">
                            <div class="mr-3"><div>Interested In</div></div>
                            <a-tooltip overlayClassName="change-tooltip-color">
                                <template slot="title">
                                    Add description of opportunity
                                </template>
                                <a-icon type="question-circle" style="line-height: 22.5px; margin-top: 5px; color: var(--orange);" />
                            </a-tooltip>
                        </div>
                        <a-form-model-item prop="interested">
                            <a-textarea
                                v-model="opportunity.interested"
                                :auto-size="{ minRows: 4, maxRows: 6 }"
                                placeholder="Add description of opportunity"
                                />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <div class="dF jE">
                    <a-button class="mr-3 cancel-button" size="large" style="width:150px" @click="cancel">CANCEL</a-button>
                    <a-button style="width:150px" type="primary" size="large" @click="submit">SAVE</a-button>
                </div>
            </a-card>
        </a-form-model>
    </div>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'
import searchContact from '@/components/common/searchContact'
import moment from 'moment'
import lostWonModal from '@/components/common/lostWonModal'
export default {
    components:{
        VNodes: {
        functional: true,
        render: (h, ctx) => ctx.props.vnodes,
        },
        BHLoading,
        searchContact,
        lostWonModal
    },
    watch:{
        '$route':{
            immediate:true,
            handler(val){
                if (val.params && val.params.id && val.params.id != '') {
                    let find = this.opportunities.find(x => x.id == val.params.id)
                    if (find) {
                        if (!find.hasOwnProperty('product')){
                            if (this.instance.productType == 'lowrise'){
                                find.product = ''
                                find.floor = ''
                            } else if (this.instance.productType == 'highrise'){
                                find.product = ''
                                find.floor = ''
                            }
                        }
                        this.defaultCascader = [find.floor, find.product]
                        this.opportunity = JSON.parse(JSON.stringify(find))
                        this.originalOpportunity = JSON.parse(JSON.stringify(find))
                        setTimeout(() => {
                            this.$store.commit('UPDATE_QUERY_CONTACT', find.contact)
                        }, 50)
                        if (this.opportunity.contact && this.opportunity.contact.id) this.opportunity.contact = this.opportunity.contact.id
                        if (this.opportunity.closeDate) this.opportunity.closeDate = this.moment(this.opportunity.closeDate)
                        if (this.opportunity.owners && this.opportunity.owners.length) {
                            // this.opportunity.owners.forEach(x => {
                            //     let id = x
                            //     if (x.id) id = x.id
                            //     let find = this.users.find(user => user.id == id)
                            //     if (find) this.userSource.push(find)
                            // })
							let ids = this.userSource.map(x => x = x.id)
							this.users.forEach(user => {
								user.name = `${user.firstName} ${user.lastName}`
								if (!ids.includes(user.id)) this.userSource.push(user)
							})
                            if (typeof this.opportunity.owners[0] == 'object') this.opportunity.owners = this.opportunity.owners.map(x => x = x.id)
                        }
                    }
                }
            }
        }
    },
    computed:{
        lostReasons() {
            return this.$store.state.contacts?.allSettings?.app?.options?.lostReasons || {}
        },
        products(){
            let products = JSON.parse(JSON.stringify(this.origProducts))
            if (this.instance.productType == 'lowrise'){
               let elevations = JSON.parse(JSON.stringify(this.elevations))
                products.sort((a,b) => {
                    return a.name - b.name
                })

                let newProducts = []
                products.forEach(x => {
                    let obj = {
                        value:'',
                        label:'',
                        children:[]
                    }
                    obj.value = x.id
                    obj.label = 'Lot ' + x.name + `${x.block ? ` (Block ${x.block})` : ''}`

                    x.units.forEach(y => {
                        let foundUnit = elevations.find(z => {
                            if (z && z.hasOwnProperty('id')) return z.id == y
                            return false
                        })
                        if (foundUnit){
                            let objChild = {
                                value:'',
                                label:'',
                                disabled:false
                            }
                            objChild.value = foundUnit.id
                            objChild.label = foundUnit.name
                            obj.children.push(objChild)
                        }
                    })
                    newProducts.push(obj)
                })
                return newProducts
            } else if (this.instance.productType == 'highrise'){
                let newProducts = []
                products.forEach(x => {
                    let obj = {
                        value:'',
                        label:'',
                        children:[]
                    }
                    obj.value = x.id
                    obj.label = x.name

                    x.units.forEach(y => {
                        let objChild = {
                            value:'',
                            label:'',
                            disabled:false
                        }
                        if (y.salesStatus != 'available') objChild.disabled = true
                        objChild.value = y.id
                        if (y.name == y.unitNumber) objChild.label = 'Unit ' + y.unitNumber
                        else objChild.label = y.name + ` (Unit ${y.unitNumber})`
                        obj.children.push(objChild)
                    })
                    newProducts.push(obj)
                })
                return newProducts
            }
        },
        elevations(){
            if (this.instance.productType == 'lowrise'){
                let elevations = JSON.parse(JSON.stringify(this.origElevations))
                elevations = elevations.map(x => {
                    if (x.unitGroup && x.unitGroup.hasOwnProperty('name')){
                        return {
                            id:x.id,
                            name:`${x.unitGroup.name} ${x.name}`
                        }
                    }
                })
                return elevations
            }
        },
        origElevations(){
            return this.$store.state.elevations
        },
        origProducts(){
            return this.$store.state.products
        },
        instance(){
            return this.$store.state.instance
        },
        owner() {
            return this.$store.state.user.user
        },
        users() {
            return this.$store.state.contacts.users
        },
        opportunities(){
            return this.$store.state.contacts.opportunities || []
        },
        dealStages() {
            return this.$store.state.contacts.allSettings.app.options.stages.list
        },
        dealSources() {
            if (this.settings && this.settings.options && this.settings.options.dealSources && this.settings.options.dealSources.length > 0) {
                return this.settings.options.dealSources
            } else {
                return [
                    {name:'No Source', id:'nosource'},
                    {name:'Email', id:'email'},
                    {name:'Cold Call', id:'coldcall'},
                    {name:'Advertising', id:'advertising'}
                ]
            }
        },
		settings(){
            return this.$store.state.contacts.allSettings.app || {}
        },
    },
    data() {
        return{
            loading:false,
            opportunity:{},
            originalOpportunity:{},
            userSource:[],
            defaultCascader:[],
            lostWon:{
                type:'',
                visible:false,
                opportunity:{},
            },
        }
    },
    methods:{
        async updateWon(obj,note){
            let opportunity = JSON.parse(JSON.stringify(obj))
            let newNote = JSON.parse(JSON.stringify(note))
            opportunity.stage = 'won'
            opportunity.probability = 100

            if (note.subject || note.content){
                await this.$api.put(`/opportunities/:instance/${opportunity.id}/note`,newNote).then( ({data}) => {
                    this.$store.commit('UPDATE_OPPORTUNITY_NOTES', data)
                    opportunity = data
                    opportunity.stage = 'won'
                    opportunity.probability = 100
                }).catch( err => {
                    console.error('ERORR', err)
                    if (err.response.data.error == 'Not Found'){
                        this.$message.error('Opportunity Not Found!')
                    }
                })
            }
            this.$api.put(`/opportunities/:instance/${opportunity.id}`,opportunity).then(async ({data}) => {
                this.$store.commit('UPDATE_OPPORTUNITY', data)

                await this.$api.post(`/timelines/:instance/opportunities/${opportunity.id}`, {date: new Date().getTime(), logNote:'This opportunity was marked as Won'})
                this.closeLostWon()
                this.$emit('updateNotes')
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        async updateLost(obj){
            let opportunity = JSON.parse(JSON.stringify(obj))
            opportunity.stage = 'lost'
            opportunity.probability = 0

            let sendObj = {}
            if (opportunity.reason) {
                let find = this.lostReasons.find(x => x.id == opportunity.reason)
                let note = {
                    subject:'Lost Opportunity',
                    content:`Lost Reason: ${find.name}`
                }
                await this.$api.put(`/opportunities/:instance/${opportunity.id}/note`,note).then(({data}) => {
                    this.$store.commit('UPDATE_OPPORTUNITY_NOTES', data)
                    sendObj = data
                    sendObj.stage = 'lost'
                    sendObj.probability = 0
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            }
            else {
                sendObj = opportunity
            }

            this.$api.put(`/opportunities/:instance/${opportunity.id}`,sendObj).then(async ({data}) => {
                this.$store.commit('UPDATE_OPPORTUNITY', data)

                this.$api.post(`/timelines/:instance/opportunities/${data.id}`, {date: new Date().getTime(), logNote:'This opportunity was marked as Lost'})
                this.closeLostWon()
                this.$emit('updateNotes')
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        closeLostWon(){
            this.lostWon = {
                type:'',
                visible:false,
                opportunity:{}
            }
        },
        markWon(obj){
            this.lostWon = {
                type:'won',
                visible: true,
                opportunity:JSON.parse(JSON.stringify(obj))
            }
        },
        markLost(obj){
            this.lostWon = {
                type:'lost',
                visible: true,
                opportunity:JSON.parse(JSON.stringify(obj))
            }
        },
        selectLot(e){
            this.opportunity.floor = e[0]
            this.opportunity.product = e[1]
            let price = 0
            this.origProducts.forEach(x => {
                if (x.id == this.opportunity.floor) {
                    let premiums = Object.values(x.other.premiums)
                    premiums.forEach(y => {
                        if (y.hasOwnProperty('active') && y.active) {
                            price += y.price
                        }
                    })
                }
            })
            let foundElevation = this.origElevations.find(x => {
                if (x && x.hasOwnProperty('id')) return x.id == this.opportunity.product
                return false
            })
            if (foundElevation){
                if (foundElevation.packages && foundElevation.packages.length){
                    price += foundElevation.packages[0].price
                }
            }
            this.opportunity.dealValue = price
        },
        selectProduct(e){
            this.opportunity.floor = e[0]
            this.opportunity.product = e[1]
			let price = 0;
			const floor = this.origProducts.find(f => f.id === this.opportunity.floor);
			if(floor){
				const unit = floor.units.find(u => u.id === this.opportunity.product);
				if(unit && unit.packages && unit.packages[0]){
                    price += unit.packages[0].price

					let unitPremiums = unit.packages[0].other && unit.packages[0].other.premiums || {}

					Object.values(unitPremiums).filter(x=>x).forEach(p => {
						if (!p.active) return
						if (p.value) p.value = +p.value
						if (isNaN(p.value)) return
						price += p.value
					})
				}
			}
            this.opportunity.dealValue = price
        },
        changeStage(e) {
            if (e == 'won') {
                this.opportunity.probability = 100
                this.markWon(this.opportunity)
            } else if (e == 'lost') {
                this.opportunity.probability = 0
                this.markLost(this.opportunity)
            } else {
                let find = this.dealStages.find(x => x.id == e)
                if (find && find.probability) this.opportunity.probability = find.probability
            }
        },
        cancel() {
            if (this.$route.params && this.$route.params.id && this.$route.params.id != '') {
                this.loading = true
                let find = this.opportunities.find(x => x.id == this.$route.params.id)
                let obj = {}
                if (find) {
                    obj = JSON.parse(JSON.stringify(find))
                    setTimeout(() => {
                        this.loading = false
                        this.$store.commit('UPDATE_QUERY_CONTACT', find.contact)
                    }, 50)
                    if (obj.contact && obj.contact.id) obj.contact = obj.contact.id
                    if (obj.closeDate) obj.closeDate = this.moment(obj.closeDate)
                    if (obj.owners && obj.owners.length) {
                        obj.owners.forEach(x => {
                            let id = x
                            if (x.id) id = x.id
                            let find = this.users.find(user => user.id == id)
                            if (find && !this.userSource.includes(find)) this.userSource.push(find)
                        })
                        if (typeof obj.owners[0] == 'object') obj.owners = obj.owners.map(x => x = x.id)
                    }
                    this.opportunity = obj
                }
            }
        },
        submit() {
            this.$refs.opportunity.validate(valid => {
                if (valid) {
                    let sendObj = JSON.parse(JSON.stringify(this.opportunity))
                    if (this.instance.productType == 'lowrise'){
                        let findFloor = this.origProducts.find(x => x.id == sendObj.floor)
                        if (findFloor){
                            let findProduct = this.origElevations.find(x => x.id == sendObj.product)
                            if (findProduct){
                                sendObj.name = `Lot ${findFloor.name}${findFloor.block ? ` (Block ${findFloor.block})` : ''} ${findProduct.unitGroup && findProduct.hasOwnProperty('name') ? '/ ' + findProduct.unitGroup.name : ''} ${findProduct.name}`
                            } else {
                                sendObj.name = '(No Product)'
                            }
                        } else {
                            sendObj.name = '(No Product)'
                        }
                    } else if (this.instance.productType == 'highrise'){
                        let findFloor = this.origProducts.find(x => x.id == sendObj.floor)
                        if (findFloor){
                            let findProduct = findFloor.units.find(x => x.id == sendObj.product)
                            if (findProduct){
                                if (findProduct.name == findProduct.unitNumber) sendObj.name = 'Unit ' + findProduct.unitNumber
                                else sendObj.name = findProduct.name + ` (Unit ${findProduct.unitNumber})`
                            } else {
                                sendObj.name = '(No Product)'
                            }
                        } else {
                            sendObj.name = '(No Product)'
                        }
                    }
                    if (sendObj.notes && sendObj.notes.length && typeof sendObj.notes[0] == 'object') sendObj.notes = sendObj.notes.map(x => x = x.id)
                    sendObj.closeDate = this.moment(sendObj.closeDate).format('X')*1000
					if (typeof sendObj.owners == 'string') sendObj.owners = [sendObj.owners]
                    this.loading=true
                    this.$api.put(`/opportunities/:instance/${sendObj.id}`,sendObj).then( ({data}) => {
                        this.originalOpportunity = JSON.parse(JSON.stringify(data));
                        this.$store.commit('UPDATE_OPPORTUNITY', data)
                        this.loading=false
                        this.$emit('update', data)
                        this.$notification['success']({
                            message: 'Update Success',
                            description: 'Your opportunity has been updated successfully.',
                            duration: 4
                        });
                    }).catch( err => {
                        this.$message.error(this.$err(err))
                    })
                }
            })
        },
        changeVisibility(e) {
            if (e == 'owners') {
                // this.opportunity.owners = this.users.map(x => x = x.id)
                let ids = this.userSource.map(x => x = x.id)
                this.users.forEach(user => {
                    user.name = `${user.firstName} ${user.lastName}`
                    if (!ids.includes(user.id)) this.userSource.push(user)
                })
            } else if (e == 'everyone') {
                // this.opportunity.owners = [this.owner.id]
                let ids = this.userSource.map(x => x = x.id)
                this.users.forEach(user => {
                    user.name = `${user.firstName} ${user.lastName}`
                    if (!ids.includes(user.id)) this.userSource.push(user)
                })
            }
        },
        searchAssign(input){
            if(input && input.length>1) {
                this.users.forEach(x => {
                    x.name = x.firstName+ ' ' + x.lastName
                })
                this.userSource = this.users.filter(({name}) => {
                    name = name.toLowerCase()
                    input = input.toLowerCase()
                    return name.includes(input)
                })
            } else {
                return this.userSource = []
            }
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        moment,
        addNewStage() {
            this.$store.commit('OPEN_LEAD_STATUS', {type:'dealStage'})
        },
        addNewSource() {
            this.$store.commit('OPEN_LEAD_STATUS', {type:'dealSource'})
        },
        req:msg=>({required:true,message:msg}),
        updatePerson(contact){
			this.$set(this.opportunity, 'contact', contact)
        },
    },
    created() {

    }
}
</script>

<style>

</style>
